import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';
import { enviarNotificacao } from './notificacoes';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const FieldValue = firebase.firestore.FieldValue;
const storage = firebase.storage();

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const atualizarPerfil = verificarLimite( async (uid, nome, dados) => {
    var { cep, descricao, arquivo, consultaCEP } = dados;
    if (!cep) {
        consultaCEP = {}
    }
    var dados = {};
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var turmas = [];
    try {
        await adicionarLimite(uid);
        const perfilRef = await firestore.collection('privado')
        .doc(uid);
        const perfilDoc = await perfilRef.get();
        const amigosRef = await firestore.collection('publico')
        .doc(nome);
        if (perfilDoc.exists) {
            if (descricao.length > 200) {
                notifyError('A descrição deve ter menos de 200 caracteres')
                return;
            }
            dados.descricao = descricao;
            if (consultaCEP.cep) {
                dados.bairro = consultaCEP.bairro;
                dados.cep = consultaCEP.cep
                dados.cidade = consultaCEP.localidade
                dados.rua = consultaCEP.logradouro
            } else if (!consultaCEP.cep && !cep) {
                dados.bairro = FieldValue.delete();
                dados.cep = FieldValue.delete();
                dados.cidade = FieldValue.delete();
                dados.rua = FieldValue.delete();
            }
            if (arquivo) {
                const storageRef = await storage.ref(`usuarios/${uid}/${arquivo.name}`);
                const uploadTask = await storageRef.put(arquivo);
                if (uploadTask) {
                    dados.fotoURL = await uploadTask.ref.getDownloadURL()
                }
            }
            const uploadPerfil = await perfilRef.set(dados, { merge: true });
            const uploadAmigos = await amigosRef.set(dados, { merge: true });
            if (dados.cep !== undefined) {
                localStorageManager.setItem('cep', cep);
            }
            if (dados.descricao !== undefined) {
                localStorageManager.setItem('descricao', dados.descricao);
            }
            if (dados.fotoURL !== undefined) {
                localStorageManager.setItem('fotoURL', dados.fotoURL);
            }
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const enviarSuporte = verificarLimite( async (uid, nome, mensagem) => {
    if (!mensagem) {
        notifyError('Complete o formulário corretamente');
        return false;
    }
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const suporteRef = await firestore.collection('suporte')
        .add({
            uid: uid,
            nome: nome,
            mensagem: mensagem,
        });
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { atualizarPerfil, enviarSuporte };