import './css/Graficos.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';
import GraficoBar from '../../components/painel/graficos/Bar';
import { coletarPorcentagensDasTurmas } from '../../firebase/graficos';

// Imagens
import NotFound from '../../img/notFound.gif';
import localStorageManager from '../../localStorageManager';

// Componente
const Graficos = () => {
  const navigate = useNavigate();
  
  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach(async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  const getTopPositionRelativeToPage = (element) => {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  };

  // LocalStorage
  const nomeLocal = localStorage.getItem('nome');
  const uidLocal = localStorage.getItem('uid');
  const emailLocal = localStorage.getItem('email');

  if (!nomeLocal || !uidLocal || !emailLocal) {
    localStorage.clear();
    window.location.href = "/entrar/";
  }
  
  useEffect(() => {
    document.title = 'Painel Super BI | Gráficos';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Estados
  const [carregando, setCarregando] = useState(false);
  const [dadosEnsinoMedio, setDadosEnsinoMedio] = useState([]);
  const [dadosEnsinoFundamental, setDadosEnsinoFundamental] = useState([]);
  const [turmaSelecionada, setTurmaSelecionada] = useState('');
  const [ensinoSelecionado, setEnsinoSelecionado] = useState('ensinoMedio');
  const [dataGraficoAtualizado, setDataGraficoAtualizado] = useState('');

  useEffect(() => {
    const recuperarDadosLocalStorage = (chave) => {
      const dadosLocalStorage = localStorage.getItem(chave);
      try {
        return dadosLocalStorage ? JSON.parse(dadosLocalStorage) : [];
      } catch (error) {
        console.error(`Erro ao fazer parse dos dados da chave ${chave}:`, error);
        return [];
      }
    };

    const dadosEnsinoMedio = recuperarDadosLocalStorage('ensinoMedio');
    const dadosEnsinoFundamental = recuperarDadosLocalStorage('ensinoFundamental');
    const dadosDataGraficoAtualizado = localStorageManager.getItem('dataGraficoAtualizado');

    setDadosEnsinoMedio(dadosEnsinoMedio);
    setDadosEnsinoFundamental(dadosEnsinoFundamental);
    setDataGraficoAtualizado(dadosDataGraficoAtualizado);

    if (ensinoSelecionado === 'ensinoMedio' && dadosEnsinoMedio.length > 0) {
      setTurmaSelecionada(dadosEnsinoMedio[0].turma);
    } else if (ensinoSelecionado === 'ensinoFundamental' && dadosEnsinoFundamental.length > 0) {
      setTurmaSelecionada(dadosEnsinoFundamental[0].turma);
    } else {
      setTurmaSelecionada('');
    }
  }, [ensinoSelecionado]);

  useEffect(() => {
    const coletarDados = async () => {
      setCarregando(true);
      try {
        await coletarPorcentagensDasTurmas(uidLocal, nomeLocal);
      } catch (error) {
        console.error(error);
      } finally {
        setCarregando(false);
      }
    };

    coletarDados();
  }, [uidLocal, nomeLocal]);
  
  return (
    <>
      <NotificationContainer />
      <main className="container-graficos">
        <section className='content-graficos'>
          {turmaSelecionada && (
            <>
              <p className='descricao-grafico'>Atualizado: <strong>{dataGraficoAtualizado}</strong></p>
              <h1 className='titulo-grafico'>Gráfico de desempenho da turma: <strong>{turmaSelecionada}</strong></h1>
            </>
          )}
          <nav className='turmas'>
            <select defaultValue={ensinoSelecionado} onChange={(e) => setEnsinoSelecionado(e.target.value)}>
              <option value="">Selecione um tipo de ensino</option>
              <option value="ensinoMedio">Ensino Médio</option>
              <option value="ensinoFundamental">Ensino Fundamental</option>
            </select>
            {ensinoSelecionado === 'ensinoMedio' && 
              dadosEnsinoMedio.map((item, index) => (
                <a 
                  onClick={() => setTurmaSelecionada(item.turma)} 
                  className={`turma ${turmaSelecionada === item.turma ? 'selecionado' : ''}`} 
                  key={index}
                >
                  {item.turma}
                </a>
              ))
            }
            {ensinoSelecionado === 'ensinoFundamental' && 
              dadosEnsinoFundamental.map((item, index) => {
                const isFirstItem = index === 0;

                if (isFirstItem) {
                  setTurmaSelecionada(item.turma);
                }

                return (
                  <a 
                    onClick={() => setTurmaSelecionada(item.turma)} 
                    className={`turma ${isFirstItem ? 'selecionado' : ''}`} 
                    key={index}
                  >
                    {item.turma}
                  </a>
                );
              })
            }
          </nav>
          {carregando ? (
            <div className='loader-bar'></div>
          ) : (
            <>
              {turmaSelecionada ? (
                <>
                  <GraficoBar
                    turma={turmaSelecionada}
                    dados={ensinoSelecionado === 'ensinoMedio' ? dadosEnsinoMedio : dadosEnsinoFundamental}
                  />
                </>
              ) : (
                <div className='nao-encontrado'>
                  <img className='imagem' src={NotFound} />
                  <h1>Nenhuma turma encontrada</h1>
                </div>
              )}
            </>
          )}
        </section>
      </main>
    </>
  );
};

export default Graficos;
