import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';
import { enviarNotificacao } from './notificacoes';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Status
// Não aceito ou Não pedido = 0
// Aceito = 1
// Pendente = 2
// Solicitação (Aceita ou não) = 3

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const coletarAmigos = verificarLimite( async (uid, nome) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var amigos = [];
    try {
        await adicionarLimite(uid);
        const amigosRef = await firestore.collection('publico')
        .doc(nome).collection('amigos').get();
        if (!amigosRef.empty) {
            const coletando = await Promise.all(amigosRef.docs.map( async (val) => {
                const status = val.data().status;
                const nomeAmigo = val.id;
                const fotoURL = val.data().fotoURL; 
                if (status === 1) {
                    amigos.push({
                        nome: nomeAmigo,
                        fotoURL: fotoURL,
                    });
                }
            }));
            if (coletando) {
                return amigos;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const coletarFotoDePerfil = verificarLimite( async (uid, amigo) => {
    if (!uid || !amigo) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const amigoRef = await firestore.collection('publico')
        .doc(amigo).get();
        if (amigoRef.exists) {
            const data = amigoRef.data();
            var fotoURL = data.fotoURL || null;
            return fotoURL;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});


const pesquisarUsuario = verificarLimite( async (uid, nome, amigo) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const usuarioRef = await firestore.collection('publico')
        .doc(amigo);
        const usuarioDoc = await usuarioRef.get();
        if (usuarioDoc.exists) {
            const data = usuarioDoc.data();
            const amizadeVerificada = await verificarAmizade(uid, nome, amigo);
            return {
                nome: data.nome,
                email: data.email,
                cep: data.cep,
                descricao: data.descricao,
                fotoURL: data.fotoURL,
                cidade: data.cidade,
                bairro: data.bairro,
                rua: data.rua,
                amizade: amizadeVerificada,
            };
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const verificarAmizade = verificarLimite( async (uid, nome, amigo) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    if (nomeLocal === amigo) {
        return false;
    }
    try {
        await adicionarLimite(uid);
        const amigoRef = await firestore.collection('publico')
        .doc(nome).collection('amigos')
        .doc(amigo).get();
        if (amigoRef.exists) {
            const data = amigoRef.data();
            return data.status || 0;
        } else {
            return 0;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const solicitarAmizade = verificarLimite( async (uid, nome, amigo) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const usuarioRef = await firestore.collection('publico')
        .doc(nome).collection('amigos')
        .doc(amigo).set({
            status: 2,
            atualizada: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });
        const amigoRef = await firestore.collection('publico')
        .doc(amigo).collection('amigos')
        .doc(nome).set({
            status: 3,
            atualizada: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });
        const notificandoAmigo = await enviarNotificacao(uid, nome, amigo, {
            titulo: `Olá ${amigo}, você recebeu uma solicitação de amizade!`,
            mensagem: `O usuário "${nome}" gostaria que você se tornasse amigo dele. Clique no botão abaixo para aceitar ou rejeitar a solicitação.`,
            tipo: 'solicitacao-pendente',
            usuario: nome,
        });
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const aceitarAmizade = verificarLimite( async (uid, nome, amigo) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const amigoFoto = await coletarFotoDePerfil(uid, amigo);
        const usuarioFoto = await coletarFotoDePerfil(uid, nome);
        const usuarioRef = await firestore.collection('publico')
        .doc(nome).collection('amigos')
        .doc(amigo).set({
            fotoURL: amigoFoto,
            status: 1,
            atualizada: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });
        const amigoRef = await firestore.collection('publico')
        .doc(amigo).collection('amigos')
        .doc(nome).set({
            fotoURL: usuarioFoto,
            status: 1,
            atualizada: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });
        const notificandoAmigo = await enviarNotificacao(uid, nome, amigo, {
            titulo: `${nome} aceitou sua solicitação de amizade!`,
            mensagem: `Agora vocês são amigos. Sinta-se à vontade para analisar as informações e gráficos dele.`,
            tipo: 'solicitacao-aceita',
            usuario: nome,
        });
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const removerAmizade = verificarLimite( async (uid, nome, amigo) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const usuarioRef = await firestore.collection('publico')
        .doc(nome).collection('amigos')
        .doc(amigo).delete();
        const amigoRef = await firestore.collection('publico')
        .doc(amigo).collection('amigos')
        .doc(nome).delete();
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { coletarAmigos, pesquisarUsuario, solicitarAmizade, aceitarAmizade, removerAmizade };