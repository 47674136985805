import '../css/Popup.css';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import localStorageManager from '../localStorageManager';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../css/custom-toast.css';

// ICONS
import { MdCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoPersonCircle } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";

const Popup = ({ children, handleExcluir, handleFechar, handleSalvar, handleEnviar, classNome }) => {

  const navigate = useNavigate();

  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  return (
    <>
      <section className={`container-popup ${classNome}`}>
        <div data-animation="top" data-duration-animation="0.4s" className='content-popup'>
          {children}
          <div className='botoes-bottom'>
            {handleFechar && (
              <button className='cancelar' onClick={handleFechar}>Cancelar</button>
            )}
            {handleExcluir && (
              <button className='excluir' onClick={handleExcluir}>Excluir</button>
            )}
            {handleSalvar && (
              <button className='salvar' onClick={handleSalvar}>Salvar</button>
            )}
            {handleEnviar && (
              <button className='enviar' onClick={handleEnviar}>Enviar</button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Popup;