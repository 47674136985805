import './css/CriarPlataforma.css';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Popup from '../Popup';
import { coletarTurmas, criarTurma, excluirTurma } from '../../firebase/turma';
import localStorageManager from '../../localStorageManager';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

// ICONS
import { MdCreateNewFolder, MdOutlineCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoPersonCircle, IoSchoolOutline, IoSearch } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix, FaTrashCan } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

const CriarTurmaApp = ({ atualizarTurmas }) => {

  // LocalStorage
  const limiteLocal = localStorageManager.getItem('limite') || 0;
  const nomeLocal = localStorageManager.getItem('nome') || null;
  const uidLocal = localStorageManager.getItem('uid') || null;
  const emailLocal = localStorageManager.getItem('email') || null;
  
  const navigate = useNavigate();

  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  // Animações
  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Modais
  const [turmas, setTurmas] = useState([]);
  const [popupCriar, setPopupCriar] = useState(false);
  const [popupExcluir, setPopupExcluir] = useState(false);
  const [carregando, setCarregando] = useState(false);
  
  // Inputs
  const [inputNomeTurma, setInputNomeTurma] = useState('');
  const [inputNomeEnsino, setInputNomeEnsino] = useState('');
  const [inputNomeTurmaExcluir, setInputNomeTurmaExcluir] = useState('');
  const [inputPesquisarTurma, setInputPesquisarTurma] = useState('');

  const handleMostrarPopupCriar = () => {
    setPopupCriar(true);
  }

  const handleMostrarPopupExcluir = () => {
    setPopupExcluir(true);
  }

  const handleFechar = () => {
    setInputNomeTurma('');
    setInputNomeEnsino('');
    setInputNomeTurmaExcluir('');
    setPopupCriar(false);
    setPopupExcluir(false);
  }

  const handleAtualizarTurmas = (turmasList) => {
    atualizarTurmas(turmasList);
    setTurmas(turmasList);
  }

  // Salvar turma
  const handleSalvar = async () => {
    try {
        if (!inputNomeEnsino || !inputNomeTurma) {
            notifyError('Complete o formulário');
            return;
        }
        const criando = await criarTurma(uidLocal, inputNomeTurma, inputNomeEnsino);
        if (criando) {
            const turmasList = await coletarTurmas(uidLocal);
            handleAtualizarTurmas(turmasList);
            handleFechar();
            notifySuccess(`Turma "${inputNomeTurma}" criada com sucesso!`);
        }
    } catch (error) {
        console.log(error);
        notifyError('Houve algum erro!');
    }
  }

  // Excluir turma
  const handleExcluir = async () => {
    try {
        if (!inputNomeTurmaExcluir) {
            notifyError('Não foi possível identificar a turma a ser excluída.');
            return;
        }
        const excluindo = await excluirTurma(uidLocal, inputNomeTurmaExcluir);
        if (excluindo) {
            const turmasList = await coletarTurmas(uidLocal);
            handleAtualizarTurmas(turmasList);
            handleFechar();
            notifySuccess(`Turma "${inputNomeTurmaExcluir}" excluída com sucesso!`);
        }
    } catch (error) {
        console.log(error);
        notifyError('Houve algum erro!');
    }
  }

  // Atualizar Turmas
  useEffect(() => {
    const atualizarTurmasEffect = async () => {
        setCarregando(true);
        try {
        const turmasList = await coletarTurmas(uidLocal);
        if (turmasList) {
            handleAtualizarTurmas(turmasList);
        } else if (turmasList === 'erro') {
            notifyError('Não foi possível encontrar suas turmas');
        }
        setCarregando(false);
      } catch (error) {
        console.log(error);
        return;
      }
    };
    
    atualizarTurmasEffect();
  }, []);

  
  return (
    <>
        <section className="container-criar-plataforma">
            <div data-animation data-duration-animation="0.5s" className='content-criar-plataforma'>
                <div className='titulo'>
                    <IoSchoolOutline className='icon' />
                    <h1>Gerenciar Turmas</h1>
                </div>

                {/* Criar Turmas */}
                <div className='botoes'>
                    <div className='input'>
                        <input maxLength={20} onChange={(e) => setInputPesquisarTurma(e.target.value)} value={inputPesquisarTurma} type='text' placeholder='Pesquisar turma'/>
                        <IoSearch className='icon' />
                    </div>
                    <button onClick={handleMostrarPopupCriar}>
                        <IoMdAdd className="icon" />
                        <p>Criar turma</p>
                    </button>
                </div>
                <div className='plataformas'>
                    {carregando ? (
                        <div className='loader-bar'></div>
                    ) : (
                        <>
                            <div className='linha principal'>
                                <div className='info'>
                                    <p>Ensino</p>
                                </div>
                                <div className='info'>
                                    <p>Turma</p>
                                </div>
                                <div className='info'>
                                    <p>Excluir</p>
                                </div>
                            </div>
                            {turmas.length > 0 ? (
                                turmas.filter((turma) => {
                                    const nomePlataforma = turma.turma.toLowerCase().replace(/\u0083/g, '');
                                    return nomePlataforma.includes(inputPesquisarTurma.toLowerCase());
                                }).length > 0 ? (
                                    turmas.filter((turma) => {
                                        const nomePlataforma = turma.turma.toLowerCase().replace(/\u0083/g, '');
                                        return nomePlataforma.includes(inputPesquisarTurma.toLowerCase());
                                    }).map((turma, index) => (
                                        <div key={index} className='linha'>
                                            <div className='info'>
                                                <p>{turma.ensino}</p>
                                            </div>
                                            <div className='info'>
                                                <p>{turma.turma}</p>
                                            </div>
                                            <div className='info'>
                                                <FaTrashCan onClick={() => {
                                                    handleMostrarPopupExcluir();
                                                    setInputNomeTurmaExcluir(turma.turma);
                                                }} className='excluir' />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='linha'>
                                        <div className='info'>
                                            <p>Nenhuma pesquisa encontrada com '{inputPesquisarTurma}'</p>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className='linha'>
                                    <div className='info'>
                                        <p>Nenhuma turma encontrada</p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section>
        
        {/* Popups */}
        {popupCriar && (
            <Popup handleSalvar={handleSalvar} handleFechar={handleFechar}>
                <div className='input'>
                    <label>Nome da Turma</label>
                    <input onChange={(e) => setInputNomeTurma(e.target.value)} type='text' placeholder='ex: 1ª Série B' />
                </div>
                <div className='input'>
                    <label>Tipo de Ensino</label>
                    <select onChange={(e) => setInputNomeEnsino(e.target.value)}>
                        <option value="">Selecione um tipo de ensino</option>
                        <option value="Ensino Médio">Ensino Médio</option>
                        <option value="Ensino Fundamental">Ensino Fundamental</option>
                    </select>
                </div>
            </Popup>
        )}
        
        {popupExcluir && (
            <Popup handleExcluir={handleExcluir} handleFechar={handleFechar}>
                <div className='input'>
                    <label>Você tem certeza que deseja excluír a turma "{inputNomeTurmaExcluir}"?</label>
                </div>
            </Popup>
        )}
    </>
  );
}

export default CriarTurmaApp;