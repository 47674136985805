import './css/Amigos.css';

// Imagens
import NotFound from '../../img/notFound.gif';

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSubmit } from 'react-router-dom';
import randomColor from 'randomcolor';

// ICONS
import { FcGoogle } from "react-icons/fc";
import { IoPersonCircle, IoSearch } from "react-icons/io5";
import { SlUserFollow, SlUserUnfollow } from "react-icons/sl";

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

import CriarPlataformaApp from '../../components/painel/CriarPlataforma';
import CriarTurmaApp from '../../components/painel/CriarTurma';
import LimiteApp from '../../components/painel/Limite';
import { aceitarAmizade, coletarAmigos, pesquisarUsuario, removerAmizade, solicitarAmizade } from '../../firebase/amigos';

const Amigos = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  const animacoesTwo = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    
    elements.forEach( async (element) => {
      element.classList.add(classAnimation);
    });
  }

  // LocalStorage
  const nomeLocal = localStorage.getItem('nome') || null;
  const uidLocal = localStorage.getItem('uid') || null;
  const emailLocal = localStorage.getItem('email') || null;
  const fotoPerfil = localStorage.getItem('fotoURL') || null;

  if (!nomeLocal || !uidLocal || !emailLocal) {
    localStorage.clear();
    window.location.href = "/entrar/";
  }
  
  useEffect(() => {
    document.title = 'Painel Super BI | Amigos';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);
    
  const color = randomColor();
  
  // Modais
  const [mdPesquisar, setMdPesquisar] = useState(true);
  const [carregando, setCarregando] = useState(false);
  const [inputPesquisar, setInputPesquisar] = useState('');
  const [usuarioPesquisado, setUsuarioPesquisado] = useState({});

  // Inputs
  const [amigos, setAmigos] = useState([]);

  const coletandoAmigos = async () => {
    setCarregando(true);
    try {
      const amigosList = await coletarAmigos(uidLocal, nomeLocal);
      if (amigosList) {
        setAmigos(amigosList);
      } else {
        setAmigos([]);
      }
      setCarregando(false);
      setTimeout(() => {
        animacoesTwo();
      }, 200);
      return true;
    } catch (error) {
      console.log(error);
      setCarregando(false);
    }
  }

  const handlePesquisar = async () => {
    if (!inputPesquisar || inputPesquisar === '') {
        notifyError('Digite o nome de usuário do seu amigo');
        setUsuarioPesquisado({});
        return;
    }
    setMdPesquisar(false);
    setCarregando(true);
    try {
        setUsuarioPesquisado({nome: inputPesquisar});
        const pesquisando = await pesquisarUsuario(uidLocal, nomeLocal, inputPesquisar);
        if (pesquisando) {
            setUsuarioPesquisado(pesquisando);
        }
        setCarregando(false);
        return true;
    } catch (error) {
        console.log(error);
    }
  }

  const handleSolicitarAmizade = async (usuario) => {
    try {
      const solicitando = await solicitarAmizade(uidLocal, nomeLocal, usuario);
      if (solicitando) {
        notifySuccess('Pedido de amizade solicitado com sucesso!');
        const pesquisando = await handlePesquisar();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleAceitarAmizade = async (usuario) => {
    try {
      const aceitando = await aceitarAmizade(uidLocal, nomeLocal, usuario);
      if (aceitando) {
        notifySuccess(`Nova amizade feita com ${usuario}`);
        const pesquisando = await handlePesquisar();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRemoverAmizade = async (usuario) => {
    try {
      const removendo = await removerAmizade(uidLocal, nomeLocal, usuario);
      if (removendo) {
        notifySuccess('Amizade removida com sucesso!');
        const pesquisando = await handlePesquisar();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    coletandoAmigos();
  }, []);

  return (
    <>
      <NotificationContainer />
      <main className="container-amigos">
        <section className='content-amigos'>
          {carregando && (
            <div className='loader-bar'></div>
          )}
          {!carregando && !usuarioPesquisado.nome && amigos.length > 0 ? (
            <div className='amigos'>
              <div className='md-search'>
                  <div className='input'>
                      <IoSearch className='icon' />
                      <input onChange={(e) => setInputPesquisar(e.target.value)} value={inputPesquisar} placeholder='Pesquise por amigos' type='text' />
                      <button onClick={handlePesquisar}>Pesquisar</button>
                  </div>
              </div>
              <div className='lista-amigos'>
                {amigos.map((val, index) => (
                  <div key={index} className='amigo'>
                    {val.fotoURL ? (
                      <img src={val.fotoURL} />
                    ) : (
                      <IoPersonCircle className='icon' />
                    )}
                    <h1>{val.nome}</h1>
                    <button onClick={() => window.open(`https://tecvit-superbi.netlify.app/graficos/${val.nome}`)}>Analisar Gráfico</button>
                  </div>
                ))}
              </div>
            </div>
          ) : !carregando ? (
              <>
                {mdPesquisar ? (
                  <div className='md-search'>
                      <h1 data-animation="bottom" data-duration-animation="1.0s">Encontrar amigos</h1>
                      <p data-animation="bottom" data-duration-animation="0.9s">Use a barra de pesquisa para encontrar e se conectar com novos amigos na nossa plataforma. Explore perfis, veja interesses comuns e envie solicitações de amizade para expandir sua rede social.</p>
                      <div data-animation="bottom" data-duration-animation="0.8s" className='input'>
                          <IoSearch className='icon' />
                          <input onChange={(e) => setInputPesquisar(e.target.value)} value={inputPesquisar} placeholder='Pesquise por amigos' type='text' />
                          <button onClick={handlePesquisar}>Pesquisar</button>
                      </div>
                      <div data-animation="bottom" data-duration-animation="0.7s" className='popular'>
                          <h2 data-animation="left" data-duration-animation="0.7s">Pesquisas populares:</h2>
                          <div className='lista'>
                            <a onClick={() => setInputPesquisar('TecVit')} data-animation="left" data-duration-animation="0.8s">TecVit</a>
                            <a onClick={() => setInputPesquisar('EscolaLeticia')} data-animation="left" data-duration-animation="0.9">EscolaLeticia</a>
                            <a onClick={() => setInputPesquisar('Desenvolvedor')} data-animation="left" data-duration-animation="1.0s">Desenvolvedor</a>
                          </div>
                      </div>
                  </div>
                ) : (
                  <section className='amigos'>
                      <div className='md-search'>
                          <div className='input'>
                              <IoSearch className='icon' />
                              <input onChange={(e) => setInputPesquisar(e.target.value)} value={inputPesquisar} placeholder='Pesquise por amigos' type='text' />
                              <button onClick={handlePesquisar}>Pesquisar</button>
                          </div>
                      </div>
                      {carregando ? (
                          <div className='loader-bar'></div>
                      ) : (
                          <>
                              {usuarioPesquisado.email ? (
                                  <>
                                      <div className='perfil-amigo'>
                                          {usuarioPesquisado.fotoURL ? (
                                            <div className='imagem'>
                                              <img src={usuarioPesquisado.fotoURL} />
                                            </div>
                                          ) : (
                                            <div className='imagem'>
                                              <IoPersonCircle className="icon" />
                                            </div>
                                          )}
                                          <h1>{usuarioPesquisado.nome}</h1>
                                          <p>{usuarioPesquisado.descricao}</p>
                                          {usuarioPesquisado.cidade && (
                                              <h3><strong>Cidade:</strong> {usuarioPesquisado.cidade}</h3>
                                          )}
                                          {usuarioPesquisado.bairro && (
                                              <h3><strong>Bairro:</strong> {usuarioPesquisado.bairro}</h3>
                                          )}
                                          {usuarioPesquisado.rua && (
                                              <h3><strong>Rua:</strong> {usuarioPesquisado.rua}</h3>
                                          )}
                                          {usuarioPesquisado.amizade !== false && (
                                            <>
                                              {usuarioPesquisado.amizade === 1 ? (
                                                <button onClick={() => handleRemoverAmizade(usuarioPesquisado.nome)} className='remover'>
                                                  <SlUserUnfollow className="icon" />
                                                  Remover amizade
                                                </button>
                                              ) : usuarioPesquisado.amizade === 2 ? (
                                                <button className='pendente'>
                                                  Pedido de amizade pendente
                                                </button>
                                              ) : usuarioPesquisado.amizade === 3 ? (
                                                <>
                                                  <h4>Esse usuário te enviou uma solicitação de amizade!</h4>
                                                  <div className='flex'>
                                                    <button onClick={() => handleAceitarAmizade(usuarioPesquisado.nome)} className='aceitar'>
                                                      Aceitar
                                                    </button>
                                                    <button onClick={() => handleRemoverAmizade(usuarioPesquisado.nome)} className='rejeitar'>
                                                      Rejeitar
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                <button onClick={() => handleSolicitarAmizade(usuarioPesquisado.nome)} className='adicionar'>
                                                  <SlUserFollow className="icon" />
                                                  Solicitar pedido de amizade
                                                </button>
                                              )}
                                              
                                            </>
                                          )}
                                      </div>
                                  </>
                              ) : (
                                  <div className='nao-encontrado'>
                                      <img className='imagem' src={NotFound} />
                                      <p>Não encontramos o usuário <strong>{usuarioPesquisado.nome}</strong></p>
                                  </div>
                              )}
                              <button onClick={() => {
                                  setMdPesquisar(true);
                                  setTimeout(() => {
                                      animacoesTwo();
                                  }, 100);
                              }} className='btn-voltar'>Voltar</button>
                          </>
                      )}
                  </section>
                )}
              </>
            ) : (
              <></>
            )}
        </section>
      </main>
    </>
  );
}

export default Amigos;