import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import consultasManager from '../consultasManager';
import axios from 'axios';
const MAX_LIMIT = 300;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();

const verificarLimite = (fn) => {
  return async (uid, ...args) => {
      const { consultas } = await coletarLimite(uid);
      if (consultas >= 100) {
          notifyError('Você atingiu seu limite diário');
          return;
      }
      return await fn(uid, ...args);
  };
};

// Coletar a data atual
const getCurrentDate = async () => {
    try {
      const dataAtual = new Date();
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
      const ano = String(dataAtual.getFullYear()).slice(-2); // Obtém os últimos dois dígitos do ano
  
      return `${dia}/${mes}/${ano}`;
    } catch (error) {
      console.error("Erro ao obter a data atual da API: ", error);
      throw new Error("Não foi possível obter a data atual.");
    }
};

const adicionarLimite = async (userId) => {
    if (!userId) {
        notifyError('Não foi possível identificar seu usuário');
        return false;
    }
    const userRef = database.ref(`/usuarios/${userId}`);
    try {
      const dataAtualizada = await getCurrentDate();
      userRef.once('value', (snapshot) => {
        const userData = snapshot.val();
  
        if (userData) {
          const { consultas, ultimaAtualizacao } = userData;
          if (ultimaAtualizacao === dataAtualizada) {
            userRef.update({
              consultas: consultas + 1
            });
          } else {
            userRef.update({
              consultas: 1,
              ultimaAtualizacao: dataAtualizada
            });
          }
          consultasManager.notify();
        } else {
          userRef.set({
            consultas: 1,
            ultimaAtualizacao: dataAtualizada
          });
          consultasManager.notify();
        }
      });

      return true;
    } catch (error) {
      console.error("Erro ao adicionar consulta: ", error);
        return false;
    }
};

const coletarLimite = async (userId) => {
  if (!userId) {
    notifyError('Não foi possível identificar seu usuário');
    return false;
  }

  const userRef = database.ref(`/usuarios/${userId}`);
  
  try {
    const dataAtualizada = await getCurrentDate();

    const snapshot = await userRef.once('value');
    const userData = snapshot.val();

    if (userData) {
      const { consultas, ultimaAtualizacao } = userData;

      if (ultimaAtualizacao === dataAtualizada) {
        const limite = parseFloat((consultas / MAX_LIMIT) * 100).toFixed(2);
        return { consultas: limite };
      } else {
        await userRef.update({
          consultas: 0,
          ultimaAtualizacao: dataAtualizada
        });
        return { consultas: 0 };
      }
    } else {
      await userRef.set({
        consultas: 0,
        ultimaAtualizacao: dataAtualizada
      });
      return { consultas: 0 };
    }
  } catch (error) {
    console.error("Erro ao coletar o limite de consultas: ", error);
    return false;
  } finally {
    consultasManager.notify();
  }
};

export { coletarLimite, adicionarLimite, verificarLimite, MAX_LIMIT }