import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QrCodeApp = () => {
  const [text, setText] = useState('Hello, world!');

  return (
    <div>
      <h1>QR Code Generator</h1>
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter text to generate QR Code"
      />
      <QRCodeCanvas value={text} size={128} />
    </div>
  );
};

export default QrCodeApp;