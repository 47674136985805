import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const coletarTurmas = verificarLimite( async (uid) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var turmas = [];
    try {
        await adicionarLimite(uid);
        const turmasRef = await firestore.collection('privado')
            .doc(uid).collection('turmas').get();
        if (!turmasRef.empty) {
            const coletado = await Promise.all(turmasRef.docs.map((turma) => {
                const nomeTurma = turma.data().turma;
                const ensino = turma.data().ensino;
                const atualizada = turma.data().atualizada;
                turmas.push({
                    turma: nomeTurma,
                    ensino: ensino,
                    atualizada: atualizada,
                });
            }));
            if (coletado) {
                return turmas;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const criarTurma = verificarLimite(async (uid, turma, ensino) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const turmasRef = await firestore.collection('privado')
            .doc(uid).collection('turmas').get();
        if (turmasRef.size >= 20) {
            notifyError('Você já possui 20 ou mais turmas cadastradas');
            return false;
        }

        const turmaDoc = await firestore.collection('privado')
            .doc(uid).collection('turmas').doc(turma).get();

        if (!turmaDoc.exists) {
            await firestore.collection('privado')
                .doc(uid).collection('turmas')
                .doc(turma).set({
                    turma,
                    ensino,
                    atualizada: firebase.firestore.FieldValue.serverTimestamp(),
                });
            return true;
        } else {
            notifyError('Essa turma já existe');
            return false;
        }

    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const excluirTurma = verificarLimite( async (uid, turma) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const turmaRef = await firestore.collection('privado')
            .doc(uid).collection('turmas').doc(turma);
        if ((await turmaRef.get()).exists) {
            const excluindo = await turmaRef.delete();
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { coletarTurmas, criarTurma, excluirTurma };