import '../css/Menu.css';

// Imagens
import LogoMenu from '../img/LogoMenu.jpg';
import NotificationGif from '../img/notifications.gif';

import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

// ICONS
import { MdCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoNotifications, IoPersonCircle } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix } from "react-icons/fa6";
import { SlUserFollow, SlUserFollowing } from "react-icons/sl";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaUserEdit, FaUserFriends } from "react-icons/fa";
import localStorageManager from '../localStorageManager';
import axios from 'axios';
import Popup from './Popup';
import { notifyError, notifySuccess } from '../toastifyServer';
import { aceitarAmizade, removerAmizade } from '../firebase/amigos';
import { atualizarPerfil, enviarSuporte } from '../firebase/perfil';
import { coletarNotificacoes, excluirNotificacao } from '../firebase/notificacoes';

const Menu = () => {

  // Valores
  const uidLocal = localStorageManager.getItem('uid') || null;
  const nomeLocal = localStorageManager.getItem('nome') || null;
  const emailLocal = localStorageManager.getItem('email') || null;
  const fotoPerfil = localStorageManager.getItem('fotoURL') || null;
  const cepLocal = localStorageManager.getItem('cep') || '';
  const descricaoLocal = localStorageManager.getItem('descricao') || '';

  const handleInputArquivo = (e) => {
    const cancelado = e.target.files.length > 0 ? false : true;
    if (cancelado) {
      setInputNomeArquivo('');
      setInputTamanhoArquivo(0);
      return;
    }
    const arquivo = e.target.files[0];
    const nomeArquivo = arquivo.name;
    const tamanhoArquivo = arquivo.size > 0 ? Number(parseFloat(arquivo.size / (1024 * 1024)).toFixed(2)) : 0;
    setInputArquivo(arquivo);
    setInputNomeArquivo(nomeArquivo);
    setInputTamanhoArquivo(tamanhoArquivo);
  }

  const handleInputCEP = async (cep) => {
    const cepLimpo = cep.replace(/\D/g, '');
    if (cepLimpo.length > 8) {
      return;
    }
    if (cepLimpo.length === 8) {
      const cepFormatado = cepLimpo.replace(/^(\d{5})(\d{3})/, '$1-$2');
      setInputCEP(cepFormatado);
      const consultaCEP = await axios.get(`https://viacep.com.br/ws/${cepLimpo}/json/`);
      if (consultaCEP.status === 200) {
        setConsultaCEP(consultaCEP.data);
      } else {
        setConsultaCEP({});
      }
    } else {
      setInputCEP(cepLimpo);
      setConsultaCEP({});
    }
  }

  useEffect(() => {
    handleInputCEP(cepLocal);
    setInputDescricao(descricaoLocal);
  }, [cepLocal, descricaoLocal]);

  
  const terminarSessao = () => {
    try {
      localStorage.clear();
      window.location.href = "/entrar";
    } catch (error) {
      console.log(error);
    }
  }  

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.replace(/\/$/, "");
  const partAfterPainel = path.split('/painel/')[1];

  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Modais
  const [mdPerfil, setMdPerfil] = useState(false);
  const [mdEditarPerfil, setMdEditarPerfil] = useState(false);
  const [mdNotificacoes, setMdNotificacoes] = useState(false);
  const [mdSuporte, setMdSuporte] = useState(false);
  const [mdPix, setMdPix] = useState(false);
  const [carregando, setCarregando] = useState(false);

  // Inputs Perfil
  const [inputNome, setInputNome] = useState(nomeLocal);
  const [inputEmail, setInputEmail] = useState(emailLocal);
  const [inputDescricao, setInputDescricao] = useState(descricaoLocal);
  const [inputArquivo, setInputArquivo] = useState(null);
  const [inputNomeArquivo, setInputNomeArquivo] = useState('');
  const [inputTamanhoArquivo, setInputTamanhoArquivo] = useState('');
  const [inputCEP, setInputCEP] = useState(cepLocal);
  const [consultaCEP, setConsultaCEP] = useState({});

  // Inputs Suporte
  const [inputComentario, setInputComentario] = useState('');

  // Inputs Notificações
  const [notificacoes, setNotificacoes] = useState([]);

  const handleMostrarMdEditarPerfil = () => {
    setMdEditarPerfil(true);
    setMdPerfil(false);
  }

  const handleMostrarMdNotificacoes = () => {
    setMdNotificacoes(true);
    setMdPerfil(false);
    handleLerNotificacoes();
  }

  const handleLerNotificacoes = async () => {
    setNotificacoes([]);
    setCarregando(true);
    try {
      const notificacoesList = await coletarNotificacoes(uidLocal, nomeLocal);
      setNotificacoes(notificacoesList);
      setCarregando(false);
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const handleMostrarMdSuporte = () => {
    setMdSuporte(true);
    setMdPerfil(false);
  }
  
  const handleMostrarMdPix = () => {
    setMdPix(true);
    setMdPerfil(false);
  }

  const handleFechar = () => {
    try {
      setMdEditarPerfil(false);
      setMdSuporte(false);
      setMdPix(false);
      setMdNotificacoes(false);
      setInputArquivo(null);
      setInputNomeArquivo('');
      setInputTamanhoArquivo(0);
      setInputCEP(cepLocal);
      setInputDescricao(descricaoLocal);
      setInputComentario('');
      setNotificacoes([]);
    } catch (error) {
      console.log(error);
    }
  }
  
  // Salvar e Editar formulários
  const handleEditarPerfil = async () => {
    try {
      if (!inputCEP) {
        
      } else if (consultaCEP.erro || !consultaCEP.cep) {
        notifyError('CEP inválido!');
        return false;
      }
      const atualizandoPerfil = await atualizarPerfil(uidLocal, nomeLocal, {
        descricao: inputDescricao,
        arquivo: inputArquivo,
        consultaCEP: consultaCEP,
        cep: inputCEP,
      });
      if (atualizandoPerfil) {
        notifySuccess('Perfil atualizado com sucesso');
        handleFechar();
      } else if (enviarSuporte === 'erro') {
        notifyError('Não foi editar seu perfil');
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleSuporte = async () => {
    try {
      const enviandoSuporte = await enviarSuporte(uidLocal, nomeLocal, inputComentario);
      if (enviandoSuporte) {
        notifySuccess('Agradecemos por compartilhar sua dúvida conosco, entraremos em contato em breve.');
        handleFechar();
      } else if (enviarSuporte === 'erro') {
        notifyError('Não foi possível enviar sua mensagem ao suporte');
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Funções das notificaçãos
  const handleAceitarAmizade = async (usuario, id) => {
    try {
      const aceitando = await aceitarAmizade(uidLocal, nomeLocal, usuario);
      if (aceitando) {
        const excluindo = await excluirNotificacao(uidLocal, nomeLocal, id);
        notifySuccess(`Nova amizade feita com ${usuario}`);
        handleFechar();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRemoverAmizade = async (usuario, id) => {
    try {
      const removendo = await removerAmizade(uidLocal, nomeLocal, usuario);
      if (removendo) {
        const excluindo = await excluirNotificacao(uidLocal, nomeLocal, id);
        notifySuccess('Amizade removida com sucesso!');
        handleFechar();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleExcluirNotificacao = async (id) => {
    try {
      const excluindo = await excluirNotificacao(uidLocal, nomeLocal, id);
      if (excluindo) {
        //notifySuccess('Notificação excluida com sucesso!');
        handleLerNotificacoes();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <header className="container-menu">
        <section className='content-menu'>
          <img className='logo' src={LogoMenu} />
          <nav className='links'>
            <Link onClick={() => setMdPerfil(false)} to="/painel/inicio" className={`link ${partAfterPainel === 'inicio' || path === '/painel' ? 'selecionado' : ''}`}>
              <MdCreateNewFolder className="icon" />
            </Link>
            <Link  onClick={() => setMdPerfil(false)} to="/painel/graficos" className={`link ${partAfterPainel === 'graficos' ? 'selecionado' : ''}`}>
              <HiMiniChartBar className="icon" />
            </Link>
            <Link onClick={() => setMdPerfil(false)} to="/painel/amigos" className={`link ${partAfterPainel === 'amigos' ? 'selecionado' : ''}`}>
              <FaUserFriends className="icon" />
            </Link>
          </nav>
          
          {fotoPerfil ? (
            <img src={fotoPerfil} onClick={() => setMdPerfil(!mdPerfil)} className='imagem perfil' />
          ) : (
            <IoPersonCircle onClick={() => setMdPerfil(!mdPerfil)} className='icon perfil' />
          )}
          
          {mdPerfil && (
            <div className='md-perfil'>
              <nav className='links'>
              <li onClick={handleMostrarMdEditarPerfil}>
                  <FaUserEdit className="icon" />
                  <p>Editar Perfil</p>
                </li>
                <li onClick={handleMostrarMdNotificacoes}>
                  <IoNotifications className="icon" />
                  <p>Notificações</p>
                </li>
                <li onClick={handleMostrarMdPix}>
                  <FaPix className="icon" />
                  <p>Ajudar com PIX</p>
                </li>
                <li onClick={handleMostrarMdSuporte}>
                  <RiCustomerService2Fill className="icon" />
                  <p>Suporte</p>
                </li>
                <div className='linha'></div>
                <li onClick={terminarSessao}>
                  <FiLogOut className="icon" />
                  <p>Terminar Sessão</p>
                </li>
              </nav>
            </div>
          )}
        </section>
      </header>
      
      {/* Popups */}
      {mdEditarPerfil && (
        <Popup handleSalvar={handleEditarPerfil} handleFechar={handleFechar}>
          <h1>
            {fotoPerfil ? (
              <img className='imagem' src={fotoPerfil} />
            ) : (
              <IoPersonCircle className='icon' />
            )}
            <strong>Olá, {inputNome}</strong>
          </h1>
          <p>Atualize suas informações e preferências aqui. Mantenha seus dados sempre corretos para uma melhor experiência.</p>
          <div className='input'>
            <label>Foto de Perfil</label>
            <div className="input-group file-input-group" data-controller="file-input">
              <input value={inputNomeArquivo} disabled readOnly className="form-control bloqueado" type="text" placeholder="Nenhum arquivo selecionado" />
              <div className="input-group-append">
                <input onChange={handleInputArquivo} type="file" className="form-control-file" id="customFile" data-target="file-input.input" data-action="file-input#display" />
                <label className="btn btn-secondary mb-0">Arquivo</label>
              </div>
            </div>
            {inputTamanhoArquivo && inputTamanhoArquivo < 1 ? (
              <a className='tamanho'>Tamanho do arquivo: <strong>{inputTamanhoArquivo} MB</strong></a>
            ) : inputTamanhoArquivo > 1 ? (
              <a className='tamanho grande'>Tamanho do arquivo: <strong>{inputTamanhoArquivo} MB</strong></a>
            ) : (
              <a className='tamanho'>Tamanho máximo do arquivo: <strong>1.00 MB</strong></a>
            )}
          </div>
          <div className='input'>
            <label>Email</label>
            <input disabled readOnly className='bloqueado' defaultValue={inputEmail} type='text' />
          </div>
          <div className='input'>
            <label>Descrição</label>
            <textarea onChange={(e) => setInputDescricao(e.target.value)} value={inputDescricao} type='text' placeholder='ex: Em direção a um ambiente escolar mais aprimorado!'></textarea>
          </div>
          <div className='input'>
            <label>CEP</label>
            <input onChange={(e) => handleInputCEP(e.target.value)} value={inputCEP} type='text' placeholder='ex: 00000-000' />
            {inputCEP.length === 9 && !consultaCEP.cep && (
                <a className='tamanho grande'><strong>não encontramos o CEP: {inputCEP}</strong></a>
            )}
          </div>
          {consultaCEP.cep ? (
            <>
              <div className='input'>
                <label>Cidade</label>
                <input disabled readOnly className='bloqueado' defaultValue={`${consultaCEP.localidade} - ${consultaCEP.uf}`} type='text' />
              </div>
              <div className='input'>
                <label>Bairro</label>
                <input disabled readOnly className='bloqueado' defaultValue={consultaCEP.bairro} type='text' />
              </div>
              <div className='input'>
                <label>Rua</label>
                <input disabled readOnly className='bloqueado' defaultValue={consultaCEP.logradouro} type='text' />
              </div>
            </>
          ) : (
            <></>
          )}
        </Popup>
      )}

      {mdNotificacoes && (
        <Popup handleFechar={handleFechar}>
          <h1>
            <IoNotifications className='icon' />
            <strong>Notificações</strong>
          </h1>
          {carregando ? (
            <div className='loader-bar'></div>
          ) : (
            <>
              {notificacoes.length > 0 ? (
                <div className='notificacoes'>
                  {notificacoes.map((notificacao, index) => (
                    <div key={index} className='notificacao'>
                      <h1>
                        {notificacao.tipo === 'solicitacao-aceita' && (
                          <SlUserFollowing className="icon" />
                        )}
                        {notificacao.tipo === 'solicitacao-pendente' && (
                          <SlUserFollow className="icon" />
                        )}
                        {notificacao.titulo}
                      </h1>
                      <p>{notificacao.mensagem}</p>
                      <div className='botoes'>
                        {notificacao.tipo === 'solicitacao-pendente' ? (
                          <>
                            <button onClick={() => handleAceitarAmizade(notificacao.usuario, notificacao.id)} className='aceitar'>
                              Aceitar
                            </button>
                            <button onClick={() => handleRemoverAmizade(notificacao.usuario, notificacao.id)} className='rejeitar'>
                              Rejeitar
                            </button>
                          </>
                        ) : (
                          <button onClick={() => handleExcluirNotificacao(notificacao.id)}>
                            Entendido
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='nao-encontrado'>
                  <img src={NotificationGif} />
                  <h1>Nenhuma notificação encontrada</h1>
                </div>
              )}
            </>  
          )}
        </Popup>
      )}

      {mdSuporte && (
        <Popup handleEnviar={handleSuporte} handleFechar={handleFechar}>
          <h1>
            <RiCustomerService2Fill className='icon' />
            <strong>Equipe de Suporte</strong>
          </h1>
          <p>Estamos à disposição para auxiliá-lo e assegurar que você tenha a melhor experiência possível com nossa plataforma.</p>
          <div className='input'>
            <label>Nome de Usuário</label>
            <input disabled readOnly className='bloqueado' defaultValue={inputNome} type='text' />
          </div>
          <div className='input'>
            <label>Comentário</label>
            <textarea onChange={(e) => setInputComentario(e.target.value)} type='text' placeholder='ex: Estou enfrentando problemas com o pagamento.'></textarea>
          </div>
        </Popup>
      )}

      {mdPix && (
        <Popup handleFechar={handleFechar}>
          <h1>
            <FaPix className='icon' />
            <strong>Colaborar com PIX</strong>
          </h1>
          <p>Colabore com a nossa plataforma realizando uma doação via Pix. Sua contribuição nos ajuda a manter e melhorar nossos serviços, garantindo uma experiência melhor para todos os usuários.</p>
        </Popup>
      )}
    </>
  );
}

export default Menu;