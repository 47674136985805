import './css/CriarPlataforma.css';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { coletarPlataformas, coletarTurmasDasPlataformas, criarPlataforma, excluirPlataforma, excluirTurmaDaPlataforma } from '../../firebase/plataforma';
import { coletarTurmas } from '../../firebase/turma';
import localStorageManager from '../../localStorageManager';
import Popup from '../Popup';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

// ICONS
import { MdCreateNewFolder, MdOutlineCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoPersonCircle, IoSearch } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix, FaTrashCan } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { BsClipboard2Data } from "react-icons/bs";
import AdicionarDadosPlataformasApp from './AdicionarDadosPlataformas';

const CriarPlataformaApp = ({ turmasList }) => {
    
    // LocalStorage
    const limiteLocal = localStorageManager.getItem('limite') || 0;
    const nomeLocal = localStorageManager.getItem('nome') || null;
    const uidLocal = localStorageManager.getItem('uid') || null;
    const emailLocal = localStorageManager.getItem('email') || null;

    const data = new Date();
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const diaFormatado = dia < 10 ? `0${dia}` : dia;
    const mesFormatado = mes < 10 ? `0${mes}` : mes;
    const dataFormatada = `${diaFormatado}/${mesFormatado}/${ano}`;

    const navigate = useNavigate();


  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  const handleCheckboxTurma = (turma) => {
    setInputTurmasSelected((prevSelectedTurmas) => {
      if (prevSelectedTurmas.includes(turma)) {
        return prevSelectedTurmas.filter((t) => t !== turma);
      } else {
        return [...prevSelectedTurmas, turma];
      }
    });
  };

  // Modais
  const [plataformas, setPlataformas] = useState([]);
  const [turmas, setTurmas] = useState(turmasList);
  const [turmasEditar, setTurmasEditar] = useState([]);
  const [popupAdicionar, setPopupAdicionar] = useState(false);
  const [turmasDaPlataforma, setTurmasDaPlataforma] = useState([]);
  const [popupCriar, setPopupCriar] = useState(false);
  const [popupEditar, setPopupEditar] = useState(false);
  const [popupExcluirTurma, setPopupExcluirTurma] = useState(false);
  const [popupExcluirPlataforma, setPopupExcluirPlataforma] = useState(false);
  const [carregando, setCarregando] = useState(false);
  
  // Inputs
  const [inputNomePlataforma, setInputNomePlataforma] = useState('');
  const [inputCorPlataforma, setInputCorPlataforma] = useState('');
  const [inputTurmasSelected, setInputTurmasSelected] = useState([]);
  const [inputNomePlataformaEditar, setInputNomePlataformaEditar] = useState('');
  const [inputNomeTurmaEditarExcluir, setInputNomeTurmaEditarExcluir] = useState([]);
  const [inputNomePlataformaEditarExcluir, setInputNomePlataformaEditarExcluir] = useState('');
  const [inputPesquisarPlataforma, setInputPesquisarPlataforma] = useState('');
  
  // Mostrar Popups
  const handleMostrarPopupAdicionar = async (plataforma) => {
    if (!turmas || turmas.length === 0) {
        notifyError('Você precisa criar uma turma primeiro!');
    } else {
      setPopupAdicionar(true);
      setInputNomePlataforma(plataforma);
      const turmasDaPlataformaList = await coletarTurmasDasPlataformas(uidLocal, plataforma);
      setTurmasDaPlataforma(turmasDaPlataformaList);
    }
  }

  const handleMostrarPopupSalvar = () => {
    if (!turmas || turmas.length === 0) {
        notifyError('Você precisa criar uma turma primeiro!');
    } else {
      setPopupCriar(true);
    }
  }

  const handleMostrarPopupExcluirTurma = () => {
    setPopupExcluirTurma(true);
  }
  
  const handleMostrarPopupExcluirPlataforma = () => {
    setPopupExcluirPlataforma(true);
    setInputNomePlataformaEditarExcluir(inputNomePlataformaEditar);
  }
                                                  

  const handleMostrarPopupEditar = async (plataforma) => {
    try {
        const coletandoTurmas = await coletarTurmasDasPlataformas(uidLocal, plataforma);
        if (coletandoTurmas) {
            setInputNomePlataformaEditar(plataforma);
            setTurmasEditar(coletandoTurmas);
            setPopupEditar(true);
        } else {
            notifyError(`Não foi possível carregar as informações da plataforma '${plataforma}}'`);
        }
    } catch (error) {
        console.log(error);
    }
  }

  const handleFechar = () => {
    setPopupCriar(false);
    setPopupEditar(false);
    setPopupAdicionar(false);
    setPopupExcluirTurma(false);
    setPopupExcluirPlataforma(false);
  }

  // Salvar plataforma
  const handleSalvar = async () => {
    try {
        if (!inputNomePlataforma) {
            notifyError('Por favor, complete o formulário');
            return;
        }
        if (!inputTurmasSelected || inputTurmasSelected.length <= 0) {
            notifyError('Por favor, escolha pelo menos uma turma');
            return;
        }
        
        const criando = await criarPlataforma(uidLocal, inputNomePlataforma, inputCorPlataforma, inputTurmasSelected);
        
        if (criando) {
            const plataformasList = await coletarPlataformas(uidLocal);
            setPlataformas(plataformasList);
            handleFechar();
            notifySuccess(`Plataforma "${inputNomePlataforma}" criada com sucesso!`);
        }
    } catch (error) {
        console.log(error);
        notifyError('Houve algum erro!')
    }
  }

    // Excluir Turma da Plataforma
    const handleExcluirTurma = async () => {
        try {
            if (!inputNomeTurmaEditarExcluir) {
                notifyError('Não foi possível identificar a turma a ser excluída da plataforma.');
                return;
            }
            const excluindo = await excluirTurmaDaPlataforma(uidLocal, inputNomePlataformaEditar, inputNomeTurmaEditarExcluir);
            if (excluindo) {
                const turmasList = await coletarTurmas(uidLocal);
                setTurmas(turmasList);
                const plataformasList = await coletarPlataformas(uidLocal);
                setPlataformas(plataformasList);
                handleFechar();
                notifySuccess(`Turma "${inputNomeTurmaEditarExcluir}" excluída com sucesso!`);
            }
        } catch (error) {
            console.log(error);
            notifyError('Houve algum erro!');
        }
    }

    const handleExcluirPlataforma = async () => {
        try {
            if (!inputNomePlataformaEditarExcluir) {
                notifyError('Não foi possível identificar a turma a ser excluída da plataforma.');
                return;
            }
            const excluindo = await excluirPlataforma(uidLocal, inputNomePlataformaEditarExcluir);
            if (excluindo) {
                const turmasList = await coletarTurmas(uidLocal);
                setTurmas(turmasList);
                const plataformasList = await coletarPlataformas(uidLocal);
                setPlataformas(plataformasList);
                handleFechar();
                notifySuccess(`Plataforma "${inputNomePlataformaEditarExcluir}" excluída com sucesso!`);
            }
        } catch (error) {
            console.log(error);
            notifyError('Houve algum erro!');
        }
    }

  useEffect(() => {
    const atualizarPlataformasEffect = async () => {
      setCarregando(true);
      try {
        const plataformasList = await coletarPlataformas(uidLocal);
        if (plataformasList) {
            setPlataformas(plataformasList)
        } else if (plataformasList === 'erro') {
            notifyError('Não foi possível encontrar suas turmas e plataformas');
        }
        setCarregando(false);   
      } catch (error) {
        console.log(error);
        return;
      }
    };
      
    atualizarPlataformasEffect();
  }, []);


  const handleFecharPopupAdicionar = () => {
    setPopupAdicionar(false);
  }

  
  useEffect(() => {
    setTurmas(turmasList);
  }, [turmasList]);
  
  return (
    <>
        <section className="container-criar-plataforma">
            <div data-animation data-duration-animation="0.5s" className='content-criar-plataforma'>
                <div className='titulo'>
                    <MdOutlineCreateNewFolder className='icon' />
                    <h1>Gerenciar Plataformas</h1>
                </div>
                
                {/* Criar PLataforma */}
                <div className='botoes'>
                    <div className='input'>
                        <input maxLength={20} onChange={(e) => setInputPesquisarPlataforma(e.target.value)} value={inputPesquisarPlataforma} type='text' placeholder='Pesquisar plataforma'/>
                        <IoSearch className='icon' />
                    </div>
                    <button onClick={handleMostrarPopupSalvar}>
                        <IoMdAdd className="icon" />
                        <p>Criar plataforma</p>
                    </button>
                </div>
                <div className='plataformas'>
                    {carregando ? (
                        <div className='loader-bar'></div>
                    ) : (
                        <>
                            <div className='linha principal'>
                                <div className='info'>
                                    <p>Data</p>
                                </div>
                                <div className='info'>
                                <p>Nome</p>
                                </div>
                                <div className='info'>
                                    <p>Status</p>
                                </div>
                                <div className='info'>
                                    <p>Turmas</p>
                                </div>
                                <div className='info'>
                                    <p>Editar</p>
                                </div>
                            </div>
                            {plataformas.length > 0 ? (
                                plataformas.filter((plataforma) => {
                                    const nomePlataforma = plataforma.plataforma.toLowerCase().replace(/\u0083/g, '');
                                    return nomePlataforma.includes(inputPesquisarPlataforma.toLowerCase());
                                }).length > 0 ? (
                                    plataformas.filter((plataforma) => {
                                        const nomePlataforma = plataforma.plataforma.toLowerCase().replace(/\u0083/g, '');
                                        return nomePlataforma.includes(inputPesquisarPlataforma.toLowerCase());
                                    }).map((plataforma, index) => (
                                        <div key={index} className='linha'>
                                            <div className='info'>
                                                <p>{plataforma.atualizada}</p>
                                            </div>
                                            <div className='info'>
                                                <p>{plataforma.plataforma}</p>
                                            </div>
                                            <div className='info'>
                                                <p className='ativa'>{plataforma.status}</p>
                                            </div>
                                            <div className='info'>
                                                <p>{plataforma.turmas}</p>
                                            </div>
                                            <div className='info'>
                                                <FaEdit onClick={() => handleMostrarPopupEditar(plataforma.plataforma)} className='editar' />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='linha'>
                                        <div className='info'>
                                            <p>Nenhuma pesquisa encontrada com '{inputPesquisarPlataforma}'</p>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className='linha'>
                                    <div className='info'>
                                        <p>Nenhuma plataforma encontrada</p>
                                    </div>
                                </div>
                            )}  
                        </>
                    )}
                </div>
                <div className='separador'></div>
                <div className='plataformas'>
                    {carregando ? (
                        <div className='loader-bar'></div>
                    ) : (
                        <>
                            <div className='linha principal'>
                                <div className='info'>
                                    <p>Data</p>
                                </div>
                                <div className='info'>
                                <p>Nome</p>
                                </div>
                                <div className='info'>
                                    <p>Status</p>
                                </div>
                                <div className='info'>
                                    <p>Turmas</p>
                                </div>
                                <div className='info'>
                                    <p>Dados</p>
                                </div>
                            </div>
                            {plataformas.length > 0 ? (
                                plataformas.filter((plataforma) => {
                                    const nomePlataforma = plataforma.plataforma.toLowerCase().replace(/\u0083/g, '');
                                    return nomePlataforma.includes(inputPesquisarPlataforma.toLowerCase());
                                }).length > 0 ? (
                                    plataformas.filter((plataforma) => {
                                        const nomePlataforma = plataforma.plataforma.toLowerCase().replace(/\u0083/g, '');
                                        return nomePlataforma.includes(inputPesquisarPlataforma.toLowerCase());
                                    }).map((plataforma, index) => (
                                        <div key={index} className='linha'>
                                            <div className='info'>
                                                <p>{plataforma.atualizada}</p>
                                            </div>
                                            <div className='info'>
                                                <p>{plataforma.plataforma}</p>
                                            </div>
                                            <div className='info'>
                                                <p className='ativa'>{plataforma.status}</p>
                                            </div>
                                            <div className='info'>
                                                <p>{plataforma.turmas}</p>
                                            </div>
                                            <div className='info'>
                                                <BsClipboard2Data onClick={() => handleMostrarPopupAdicionar(plataforma.plataforma)} className='adicionar' />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='linha'>
                                        <div className='info'>
                                            <p>Nenhuma pesquisa encontrada com '{inputPesquisarPlataforma}'</p>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className='linha'>
                                    <div className='info'>
                                        <p>Nenhuma plataforma encontrada</p>
                                    </div>
                                </div>
                            )}  
                        </>
                    )}
                </div>
            </div>
        </section>
        
        {/* Adicionar Dados */}
        {popupAdicionar && (
            <AdicionarDadosPlataformasApp 
                statusPopupAdicionar={popupAdicionar} 
                plataformasList={plataformas} 
                turmasList={turmas}
                nomePlataforma={inputNomePlataforma}
                fecharPopup={handleFecharPopupAdicionar}
                turmasDaPlataformaList={turmasDaPlataforma}
            />
        )}            
        
        {/* Popups */}
        {popupEditar && (
            <Popup handleExcluir={handleMostrarPopupExcluirPlataforma} handleFechar={handleFechar} handleSalvar={handleSalvar}>
                <div className='input'>
                    <label>Nome da Plataforma</label>
                    <input disabled readOnly className='bloqueado' value={inputNomePlataformaEditar} type='text' placeholder='ex: Alura' />
                </div>
                <div className='lista-checkbox'>
                    <label>Turmas cadastradas nessa plataforma</label>
                    {turmasEditar.map((turma, index) => (
                        <div key={index} className='input'>
                            <p>Turma: <strong>{turma.turma}</strong> - Ensino: <strong>{turma.ensino}</strong></p>
                            <FaTrashCan onClick={() => {
                                setInputNomeTurmaEditarExcluir(turma.turma);
                                handleMostrarPopupExcluirTurma();
                            }} className='icon excluir' />
                        </div>
                    ))}
                </div>
            </Popup>
        )}

        {popupCriar && (
            <Popup handleFechar={handleFechar} handleSalvar={handleSalvar}>
                <div className='input'>
                    <label>Nome da Plataforma</label>
                    <input onChange={(e) => setInputNomePlataforma(e.target.value)} type='text' placeholder='ex: Alura' />
                </div>
                <div className='input'>
                    <label>Cor da Plataforma</label>
                    <input onChange={(e) => setInputCorPlataforma(e.target.value)} type='color' defaultValue='#144774' />
                </div>
                <div className='lista-checkbox'>
                    <label>Turmas cadastradas nessa plataforma</label>
                    {turmas.map((turma, index) => (
                        <div key={index} className='input'>
                            <input onChange={() => handleCheckboxTurma(turma)} type="checkbox" className="switch" />
                            <p>Turma: <strong>{turma.turma}</strong> - Ensino: <strong>{turma.ensino}</strong></p>
                        </div>
                    ))}
                </div>
            </Popup>
        )}
        
        {popupExcluirTurma && (
            <Popup handleExcluir={handleExcluirTurma} handleFechar={handleFechar}>
                <div className='input'>
                    <label>Você tem certeza que deseja excluír a turma "{inputNomeTurmaEditarExcluir}" da plataforma "{inputNomePlataformaEditar}"?</label>
                </div>
            </Popup>
        )}

        {popupExcluirPlataforma && (
            <Popup handleExcluir={handleExcluirPlataforma} handleFechar={handleFechar}>
                <div className='input'>
                    <label>Você tem certeza que deseja excluír a plataforma "{inputNomePlataformaEditarExcluir}"?</label>
                </div>
            </Popup>
        )}
    </>
  );
}

export default CriarPlataformaApp;