import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const coletarNotificacoes = verificarLimite( async (uid, nome) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var notificacoes = [];
    try {
        await adicionarLimite(uid);
        const notificacoesRef = await firestore.collection('publico')
            .doc(nome).collection('notificacoes').get();
        if (!notificacoesRef.empty) {
            const coletado = await Promise.all(notificacoesRef.docs.map((notificacao) => {
                const titulo = notificacao.data().titulo;
                const mensagem = notificacao.data().mensagem;
                const tipo = notificacao.data().tipo;
                const usuario = notificacao.data().usuario;
                const atualizada = notificacao.data().atualizada;
                const id = notificacao.id;
                notificacoes.push({
                    titulo,
                    mensagem,
                    tipo,
                    usuario,
                    atualizada,
                    id,
                });
            }));
            if (coletado) {
                return notificacoes;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const enviarNotificacao = verificarLimite( async (uid, nome, usuario, dados) => {
    const { titulo, mensagem, tipo } = dados;
    if (!titulo || !mensagem || !tipo) {
        notifyError('Complete o formulário corretamente');
        return false;
    }
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const usuarioRef = await firestore.collection('publico')
        .doc(usuario).collection('notificacoes')
        .add({
            titulo: titulo,
            mensagem: mensagem,
            tipo: tipo,
            usuario: nome,
            atualizada: firebase.firestore.FieldValue.serverTimestamp()
        });
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});


const excluirNotificacao = verificarLimite( async (uid, nome, id) => {
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const notificacaoRef = await firestore.collection('publico')
        .doc(nome).collection('notificacoes')
        .doc(id).delete();
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { coletarNotificacoes, enviarNotificacao, excluirNotificacao };