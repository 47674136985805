import './css/Logar.css';
import './css/Logar-Mobile.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cadastrarComEmail, cadastrarComGoogle } from '../../firebase/logar';

// Imagens
import LogoColuna from '../../img/logoColuna.jpg';
import LoginGif from '../../img/login.gif';

// ICONS
import { FcGoogle } from "react-icons/fc";

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';


const Cadastrar = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    document.title = 'TecVit Super BI | Cadastrar';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Modais
  const [carregando, setCarregando] = useState(false);

  // Inputs
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
  };

  const handleNomeUsuario = (valor) => {
    const valorFormatado = formatarNomeDeUsuario(valor);
    setNomeUsuario(valorFormatado);
  }

  // Funções
  const handleCadastrarComEmail = async () => {
    setCarregando(true);
    try {
        if (!nomeUsuario || !email || !senha) {
            notifyError('Nome, email ou senha inválidos');
            setCarregando(false);
            return;
        }
        if (senha.length < 6) {
            notifyError('Senha menor que 6 dígitos');
            setCarregando(false);
            return;
        }
        const status = await cadastrarComEmail(nomeUsuario, email, senha);
        if (status) {
            if (status === 'sucesso') {
                notifySuccess(`Usuário cadastrado com sucesso, redirecionando..`);
                setTimeout(() => {
                    navigate('/entrar');
                }, 4500);
            } else if (status === 'email-invalido') {
                notifyError('Formato do email inválido');
            } else if (status === 'email-em-uso') {
              notifyError('Este email está sendo usado');
            } else if (status === 'nome-de-usuario-existe') {
              notifyError('O nome de usuário já está em uso');
            } else if (status === 'credencial-invalida') {
                notifyError('Email ou senha incorretos');
            } else if (status === 'erro') {
                notifyError('Houve algum erro');
            }
            setCarregando(false);
        }
    } catch (error) {
        console.log(error);
        setCarregando(false);
    }
  }

  // GOOGLE
  const handleCadastrarComGoogle = async () => {
      setCarregando(true);
      try {
          const status = await cadastrarComGoogle();
          if (status) {
              if (status === 'sucesso') {
                  notifySuccess(`Usuário cadastrado com sucesso, redirecionando..`);
                  setTimeout(() => {
                      navigate('/entrar');
                  }, 4500);
              } else if (status === 'usuario-existe') {
                notifyError('Usuário já existe!');
              } else if (status === 'nome-de-usuario-existe') {
                notifyError('O nome de usuário já está em uso');
              }
              setCarregando(false);
          }
      } catch (error) {
          console.log(error);
          setCarregando(false);
      }
  }


  return (
    <>
      <NotificationContainer />
      <main className="container-logar">
        <section className='content-logar'>
          <div className='left'>
            <img data-animation="bottom" data-duration-animation="1.1s" className='imagem' src={LoginGif} />
          </div>
          <div className='right'>
            <div className='form'>
              <img data-animation="left" data-duration-animation="0.5s" className='logo' src={LogoColuna} />
              <h1 data-animation="left" data-duration-animation="0.6s">Olá! Pronto para se cadastrar?</h1>
              <p data-animation="left" data-duration-animation="0.7s">Por favor cadastrar-se com sua conta.</p>
              <button onClick={handleCadastrarComGoogle} data-animation="left" data-duration-animation="0.8s" className='btn-google'>
                {carregando ? (
                  <div className='loader-bar'></div>
                ) : (
                  <>
                    <FcGoogle className='icon' />
                    <p>Cadastrar com Google</p>
                  </>
                )}
              </button>
              <div data-animation="left" data-duration-animation="0.9s" className='separador'>
                <p>OU</p>
              </div>
              <div data-animation="left" data-duration-animation="1.0s" className='input'>
                <label>Nome de usuário</label>
                <input onChange={(e) => handleNomeUsuario(e.target.value)} name='nome' value={nomeUsuario} type="text" placeholder='ex: JPstaconi' />
              </div>
              <div data-animation="left" data-duration-animation="1.1s" className='input'>
                <label>E-mail</label>
                <input onChange={(e) => setEmail(e.target.value)} name="email" type="text" placeholder='email@exemplo.com' />
              </div>
              <div data-animation="left" data-duration-animation="1.2s" className='input'>
                <label>Senha</label>
                <input onChange={(e) => setSenha(e.target.value)} name='senha' type="password" placeholder='exemplo123456' />
              </div>
              <button onClick={handleCadastrarComEmail} data-animation="left" data-duration-animation="1.3s">
                {carregando ? (
                  <div className='loader-bar'></div>
                ) : (
                  <>Cadastrar</>
                )}  
              </button>
              <a data-animation="left" data-duration-animation="1.4s">Já possui conta? <strong onClick={() => navigate('/entrar')}>Entrar</strong></a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Cadastrar;