import './css/Logar.css';
import './css/Logar-Mobile.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { entrarComEmail, entrarComGoogle } from '../../firebase/logar';

// Imagens
import LogoColuna from '../../img/logoColuna.jpg';
import LoginGif from '../../img/login.gif';

// ICONS
import { FcGoogle } from "react-icons/fc";

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';


const Entrar = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    document.title = 'TecVit Super BI | Entrar';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Modais
  const [carregando, setCarregando] = useState(false);

  // inputs
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');


  // Funções
  const handleEntrarComEmail = async () => {
    setCarregando(true);
    try {
        if (!email || !senha) {
            notifyError('Email ou senha inválidos');
            setCarregando(false);
            return;
        }
        if (senha.length < 6) {
            notifyError('Senha menor que 6 dígitos');
            setCarregando(false);
            return;
        }
        const status = await entrarComEmail(email, senha);
        if (status) {
            if (status === 'sucesso') {
                notifySuccess(`Usuário logado com sucesso, Bem-Vindo ${localStorage.getItem('nome')}`);
                setTimeout(() => {
                  window.location.href = "/painel/inicio";
                }, 3750);
            } else if (status === 'usuario-nao-existe') {
                notifyError('Usuário não existe');
            } else if (status === 'email-invalido') {
                notifyError('Formato do email inválido');
            } else if (status === 'credencial-invalida') {
                notifyError('Email ou senha incorretos');
            } else if (status === 'erro') {
                notifyError('Houve algum erro');
            }
            setCarregando(false);
        }
    } catch (error) {
        console.log(error);
        setCarregando(false);
    }
  }

  // GOOGLE
  const handleEntrarComGoogle = async () => {
      setCarregando(true);
      try {
          const status = await entrarComGoogle();
          if (status) {
              if (status === 'sucesso') {
                  notifySuccess(`Usuário logado com sucesso, Bem-Vindo ${localStorage.getItem('nome')}!`);
                  setTimeout(() => {
                    window.location.href = "/painel/inicio";
                  }, 3750);
              } else if (status === 'usuario-nao-existe') {
                  notifyError('Usuário não existe');
              } else if (status === 'credencial-invalida') {
                  notifyError('Email ou senha inválidos');
              }
              setCarregando(false);
          }
      } catch (error) {
          console.log(error);
          setCarregando(false);
      }
  }


  return (
    <>
      <NotificationContainer />
      <main className="container-logar">
        <section className='content-logar'>
          <div className='left'>
            <img data-animation="bottom" data-duration-animation="1.1s" className='imagem' src={LoginGif} />
          </div>
          <div className='right'>
            <div className='form'>
              <img data-animation="left" data-duration-animation="0.5s" className='logo' src={LogoColuna} />
              <h1 data-animation="left" data-duration-animation="0.6s">Bem-Vindo Novamente</h1>
              <p data-animation="left" data-duration-animation="0.7s">Por favor entre com sua conta.</p>
              <button onClick={handleEntrarComGoogle} data-animation="left" data-duration-animation="0.8s" className='btn-google'>
                {carregando ? (
                  <div className='loader-bar'></div>
                ) : (
                  <>
                    <FcGoogle className='icon' />
                    <p>Entrar com Google</p>
                  </>
                )}
              </button>
              <div data-animation="left" data-duration-animation="0.9s" className='separador'>
                <p>OU</p>
              </div>
              <div data-animation="left" data-duration-animation="1.0s" className='input'>
                <label>E-mail</label>
                <input onChange={(e) => setEmail(e.target.value)} name='email' type="text" placeholder='email@exemplo.com' />
              </div>
              <div data-animation="left" data-duration-animation="1.1s" className='input'>
                <label>Senha</label>
                <input onChange={(e) => setSenha(e.target.value)} name='senha' type="password" placeholder='exemplo123456' />
              </div>
              <button onClick={handleEntrarComEmail} data-animation="left" data-duration-animation="1.1s">
                {carregando ? (
                  <div className='loader-bar'></div>
                ) : (
                  <>Entrar</>
                )}
              </button>
              <a data-animation="left" data-duration-animation="1.2s">Não possui conta? <strong onClick={() => navigate('/cadastrar')}>Cadastrar</strong></a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Entrar;