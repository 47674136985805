import '../css/Error404.css';

// Imagens
import Error404Png from '../img/error404.png';
import LogoLinha from '../img/logoLinha.jpg';


import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../css/custom-toast.css';

// ICONS
import { FcGoogle } from "react-icons/fc";


const Error404 = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    document.title = 'TecVit Super BI | Erro 404';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  return (
    <>
      <NotificationContainer />
      <main className="container-error404">
        <section className='content-error404'>
          <div className='left'>
            <img data-animation="bottom" data-duration-animation="1.1s" src={Error404Png} />
          </div>
          <div className='right'>
            <img data-animation="left" data-duration-animation="0.6s" className='logo' src={LogoLinha} />
            <h1 data-animation="left" data-duration-animation="0.7s">404...</h1>
            <h2 data-animation="left" data-duration-animation="0.8s">Repito, 404. Câmbio!</h2>
            <h3 data-animation="left" data-duration-animation="0.9s">Estação Responde:</h3>
            <p data-animation="left" data-duration-animation="1.0s">Acho que você chegou ao limite do universo. <br />A página que você requisitou não foi encontrada.</p> 
            <button data-animation="left" data-duration-animation="1.1s" onClick={() => navigate('/painel/inicio')}>Retornar ao início</button>
          </div>
        </section>
      </main>
    </>
  );
}

export default Error404;