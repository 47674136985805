import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';
import { enviarNotificacao } from './notificacoes';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

function getDate() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // +1 porque os meses começam de 0
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
}

function filterDates(dates) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Ajusta a hora para 00:00:00 para comparar apenas as datas
    const currentTimestamp = currentDate.getTime();
    const dateObjects = dates.map(date => {
        const [day, month, year] = date.split('-');
        return new Date(`${year}-${month}-${day}T00:00:00`);
    });
    dateObjects.sort((a, b) => a - b);
    let nearestDate = null;
    for (let date of dateObjects) {
        if (date.getTime() === currentTimestamp) {
            nearestDate = date;
            break;
        } else if (date.getTime() > currentTimestamp) {
            nearestDate = date;
            break;
        } else {
            nearestDate = date;
        }
    }
    if (nearestDate) {
        const day = nearestDate.getDate().toString().padStart(2, '0');
        const month = (nearestDate.getMonth() + 1).toString().padStart(2, '0');
        const year = nearestDate.getFullYear();
        return `${day}-${month}-${year}`;
    } else {
        return null;
    }
}

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const enviarPorcentagemDaTurma = verificarLimite( async (uid, nome, plataforma, ensino, turma, porcentagem) => {
    if (!turma || !porcentagem) {
        notifyError('Complete o formulário corretamente');
        return false;
    }
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const dia = String(await getDate());
        const dataRef = await firestore.collection('publico').doc(nome)
        .collection('graficos').doc(dia).set({
            data: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true });
        const dadosRef = await firestore.collection('publico').doc(nome)
        .collection('graficos').doc(dia)
        .collection(ensino).doc(turma).set({
            [plataforma]: porcentagem,
            turma: turma,
            ensino: ensino,
            atualizada: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const coletarDataRecente = verificarLimite( async (uid, nome) => {
    // Totais de  leituras = 1
    // 1 Leitura para coletar o dia mais recente
    
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const dia = String(await getDate());
        const datasRef = await firestore.collection('publico')
        .doc(nome).collection('graficos')
        .orderBy('data', 'desc')
        .limit(1).get();

        const datas = datasRef.docs.map((doc) => (doc.id));
        const dataFiltrada = await filterDates(datas);
        if (dataFiltrada) {
            return dataFiltrada;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const coletarPorcentagensDasTurmas = verificarLimite( async (uid, nome) => {
    // Totais de  leituras
    // 1 Leitura para coletar o dia mais recente
    // N Leituras dependendo de quantos documentos o usuário possuir
    
    if (!uid || !nome) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const dataRecente = await coletarDataRecente(uid, nome);
        
        // Ensino Médio
        const ensinoMedioRef = await firestore.collection('publico')
        .doc(nome).collection('graficos')
        .doc(dataRecente).collection('Ensino Médio')
        .limit(15)
        .get();
        const dadosEnsinoMedio = ensinoMedioRef.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));

        // Ensino Fundamental
        const ensinoFundamentalRef = await firestore.collection('publico')
        .doc(nome).collection('graficos')
        .doc(dataRecente).collection('Ensino Fundamental')
        .limit(15)
        .get();
       
        const dadosEnsinoFundamental = ensinoFundamentalRef.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));

        if (dadosEnsinoMedio.length > 0) {
            localStorageManager.setItem('ensinoMedio', JSON.stringify(dadosEnsinoMedio));
        }
        if (dadosEnsinoFundamental.length > 0) {
            localStorageManager.setItem('ensinoFundamental', JSON.stringify(dadosEnsinoFundamental));
        }
        localStorageManager.setItem('dataGraficoAtualizado', dataRecente.replace(/-/g, '/'));
        return true;
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { enviarPorcentagemDaTurma, coletarPorcentagensDasTurmas };