import './css/Home.css';

// Imagens
import LogoLinha from './img/logoLinha.jpg';

// ICONS
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { PiSecurityCameraFill } from "react-icons/pi";

import { FaCamera } from "react-icons/fa"; // CAMERA
import { FaMapMarkerAlt } from "react-icons/fa"; // LOCALIZACAO
import { BsFillHddNetworkFill } from "react-icons/bs"; // IP DE REDE
import { IoDesktop } from "react-icons/io5"; // MONITOR
import { FaFileAlt } from "react-icons/fa"; // ARQUIVO E DOCUMENTO
import { FaClock } from "react-icons/fa6"; // RELOGIO
import { ImQuotesLeft } from "react-icons/im"; // ASPAS
import { FaStar } from "react-icons/fa"; // ESTRELA
import { useEffect, useState } from 'react';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from './toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import './css/custom-toast.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    document.title = 'TecVit Super BI | Início';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/painel/inicio');
    }, 4400);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <NotificationContainer />
      <main className="container-home">
        <section className='content-home'>
          <img data-animation className='logo' src={LogoLinha} />
          <div data-animation className='loader-progress'></div>
        </section>
      </main>
    </>
  );
}

export default Home;