import './css/Inicio.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import { FcGoogle } from "react-icons/fc";

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

// Componentes
import CriarPlataformaApp from '../../components/painel/CriarPlataforma';
import CriarTurmaApp from '../../components/painel/CriarTurma';
import LimiteApp from '../../components/painel/Limite';

const Inicio = () => {

  const navigate = useNavigate();
  
  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  // LocalStorage
  const nomeLocal = localStorage.getItem('nome') || null;
  const uidLocal = localStorage.getItem('uid') || null;
  const emailLocal = localStorage.getItem('email') || null;

  if (!nomeLocal || !uidLocal || !emailLocal) {
    localStorage.clear();
    window.location.href = "/entrar/";
  }
  
  useEffect(() => {
    document.title = 'Painel Super BI | Início';
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Inputs
  const [turmas, setTurmas] = useState([]);

  const atualizarTurmas = (turmasList) => {
    setTurmas(turmasList);
  }

  return (
    <>
      <NotificationContainer />
      <main className="container-inicio">
        <section className='content-inicio'>
          <div className='left'>
           <LimiteApp />
           <CriarPlataformaApp turmasList={turmas} />
          </div>
          <div className='right'>
            <CriarTurmaApp atualizarTurmas={atualizarTurmas} />
          </div>
        </section>
      </main>
    </>
  );
}

export default Inicio;