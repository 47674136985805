import './css/Limite.css';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Popup from '../Popup';
import { coletarTurmas, criarTurma, excluirTurma } from '../../firebase/turma';
import localStorageManager from '../../localStorageManager';
import { coletarLimite } from '../../firebase/plataforma';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

// ICONS
import { MdCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoLogoFirebase, IoPersonCircle, IoSearch } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix, FaTrashCan } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp, IoMdAdd } from "react-icons/io";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { MAX_LIMIT } from '../../firebase/limites';
import consultasManager from '../../consultasManager';

const LimiteApp = () => {

  // LocalStorage
  const limiteLocal = localStorageManager.getItem('limite') || 0;
  const nomeLocal = localStorageManager.getItem('nome') || null;
  const uidLocal = localStorageManager.getItem('uid') || null;
  const emailLocal = localStorageManager.getItem('email') || null;
  
  const navigate = useNavigate();

  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  // Animações
  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Valores
  const [limite, setLimite] = useState(parseInt((parseInt(limiteLocal) / 200) * 100));

  useEffect(() => {
    const unsubscribe = localStorageManager.subscribe((key, value) => {
      if (key === 'limite') {
        setLimite(parseInt((parseInt(value) / 200) * 100));
      }
    });
    return () => unsubscribe();
  }, []);

  // Modais
  const [mdInfo, setMdInfo] = useState(false);

  // Atualizar Limites
  const updateLimit = async () => {
    try {
      const { consultas } = await coletarLimite(uidLocal);
      setLimite(consultas);
    } catch (error) {
      console.error("Erro ao obter o limite de consultas:", error);
    }
  };

  useEffect(() => {
    updateLimit();
  }, [uidLocal, consultasManager]);

  return (
    <>
        <section className="container-limite">
            <div data-animation data-duration-animation="0.5s" className='content-limite'>
                <div className='flex'>
                    <BsDatabaseFillExclamation className='icon' />
                    <div className='column'>
                        <h1>Limite de consultas diarias</h1>
                        <div className='flex'>
                         <div style={{
                            background: `linear-gradient(90deg, ${limite >= 100 ? 'var(--red) 100%' : 'var(--three) ' + limite + '%'}, var(--slateTen) 0%)`
                          }} className='barra-de-progresso'></div>
                          <p style={{
                            color: `${limite >= 100 ? 'var(--red)' : 'var(--slateTen)'}`
                          }}>{limite}%</p>
                        </div>
                    </div>
                </div>
                {/* 
                <div className='linha'></div>
                {mdInfo && (
                  <div className='info'>
                      <div className='titulo'>
                        <div className='flex'>
                          <IoLogoFirebase className='icon firebase' />
                          <h2>Firebase</h2>
                        </div>
                        <button onClick={() => window.open('https://cloud.google.com/firestore/pricing?authuser=0&hl=pt')}>Consultar Preços</button>
                      </div>
                      <div className='column'>
                        <p>Utilizamos o Firebase como nosso banco de dados principal, com preços determinados pela quantidade de dados armazenados e operações realizadas, proporcionando eficiência e controle de custos.</p>
                      </div>
                  </div>
                )}

                {mdInfo ? (
                  <div onClick={() => setMdInfo(false)} className='esconder-info'>
                    <IoIosArrowUp className='icon' />
                    <p>Mostrar menos</p>
                  </div>
                ) : (
                  <div onClick={() => setMdInfo(true)} className='mostrar-info'>
                    <IoIosArrowDown className='icon' />
                    <p>Mostrar mais</p>
                  </div>
                )}
                
                */}
                
            </div>
        </section>
    </>
  );
}

export default LimiteApp;