import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './framework.scss';
import Router from './Router';

// Imagens
import Error404Png from './img/error404.png';
import LogoLinha from './img/logoLinha.jpg';
import LogoLinhaPng from './img/logoLinha.png';
import LogoColuna from './img/logoColuna.jpg';
import LogoColunaPng from './img/logoColuna.png';
import LoginGif from './img/login.gif';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <Router />
  //</React.StrictMode>
);