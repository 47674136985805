import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth, firestore } from './firebase/logar.js';
import localStorageManager from './localStorageManager';

/* Página Inicial */
import Home from './Home';

/* Componentes */
import Menu from './components/Menu';

/* Páginas Gerais */
import Qrcode from './pages/qrcode/Qrcode.js';
import Entrar from './pages/logar/Entrar';
import Cadastrar from './pages/logar/Cadastrar';
import Error404 from './pages/Error404';

/* Painel */
import Inicio from './pages/painel/Inicio';
import Amigos from './pages/painel/Amigos';
import Graficos from './pages/painel/Graficos';


const RouterApp = () => {

  // Valores
  const url = window.location.pathname.replace(/\/$/, "") || null;
  const uidLocal = localStorageManager.getItem('uid') || null;
  const nomeLocal = localStorageManager.getItem('nome') || null;
  const emailLocal = localStorageManager.getItem('email') || null;

  useEffect(() => {
    const verificarLogin = async  () => {
      try {
        auth.onAuthStateChanged( async function(user) {
          if (!user) {
            localStorage.clear();
            window.location.href = "/entrar/";
          } else {
            if (!nomeLocal || !uidLocal || !emailLocal || uidLocal !== user.uid) {
              localStorage.clear();
              window.location.href = "/entrar/";
            }
            const nomeLogin = await firestore.collection('usuarios').doc(uidLocal).get();
            if (!nomeLogin.exists || nomeLogin.exists && nomeLogin.data().nome !== nomeLocal) {
              localStorage.clear();
              window.location.href = "/entrar/";
              return false;
            } else {
              return true;
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (nomeLocal || uidLocal || emailLocal) {
      return () => {
        verificarLogin();
      }
    }
  }, []);
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/qrcode" element={<Qrcode />} />
        <Route path="/entrar" element={<Entrar />} />
        <Route path="/cadastrar" element={<Cadastrar />} />    
        <Route path="/painel/*" element={
          <div className='painel'>
            <Menu />
            <div className='content-painel'>
              <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/inicio" element={<Inicio />} />
                <Route path="/graficos" element={<Graficos />} />
                <Route path="/amigos" element={<Amigos />} />
                <Route path="/*" element={<Error404 />} />
              </Routes>
            </div>
          </div>
        } />    
      </Routes>
    </Router>
  );

}

export default RouterApp;