const listeners = [];

const localStorageManager = {
  getItem(key) {
    return localStorage.getItem(key);
  },
  
  setItem(key, value) {
    localStorage.setItem(key, value);
    listeners.forEach(listener => listener(key, value));
  },
  
  subscribe(listener) {
    listeners.push(listener);
    
    return () => {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    };
  }
};

export default localStorageManager;