import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig } from './firebaseConfig';
import CriarPlataforma from '../components/painel/CriarPlataforma';
import { notifyError } from '../toastifyServer';
import localStorageManager from '../localStorageManager';
import { coletarLimite, adicionarLimite, verificarLimite } from './limites';

const nomeLocal = localStorageManager.getItem('nome') || null;
const uidLocal = localStorageManager.getItem('uid') || null;
const emailLocal = localStorageManager.getItem('email') || null;

// Inicializando o Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const database = firebase.database();

const formatarNomeDeUsuario = (valor) => {
    valor = valor.replace(/\s+/g, '');
    valor = valor.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    valor = valor.replace(/[^a-zA-Z0-9]/g, '');
    return valor;
};

const coletarPlataformas = verificarLimite( async (uid) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var plataformas = [];
    try {
        await adicionarLimite(uid);
        const plataformasRef = await firestore.collection('privado')
        .doc(uid).collection('plataformas').get();
        if (!plataformasRef.empty) {
            const coletado = await Promise.all(plataformasRef.docs.map( async (turma) => {
                const plataforma = turma.data().plataforma;
                const status = turma.data().status;
                const cor = turma.data().cor;
                const atualizada = turma.data().atualizada;
                const dataFormatada = atualizada.toDate().toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                }).replace(/\//g, '/');
                const turmas = turma.data().turmas;
                if (turmas === 0) {
                    const excluindoPlataforma = await excluirPlataforma(uid, plataforma);
                    return;
                }
                plataformas.push({
                    plataforma,
                    status,
                    atualizada: dataFormatada,
                    turmas,
                    cor,
                });
            }));
            if (coletado) {
                return plataformas;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const coletarTurmasDasPlataformas = verificarLimite( async (uid, plataforma) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    var turmas = [];
    try {
        await adicionarLimite(uid);
        const turmasRef = await firestore.collection('privado')
        .doc(uid).collection('plataformas')
        .doc(plataforma).collection('turmas').get();
        if (!turmasRef.empty) {
            const coletado = await Promise.all(turmasRef.docs.map((turma) => {
                const nomeTurma = turma.data().turma;
                const ensino = turma.data().ensino;
                const atualizada = turma.data().atualizada;
                const dataFormatada = atualizada.toDate().toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                }).replace(/\//g, '/');
                turmas.push({
                    ensino: ensino,
                    atualizada: dataFormatada,
                    turma: nomeTurma,
                });
            }));
            if (coletado) {
                return turmas;
            }
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const criarPlataforma = verificarLimite(async (uid, plataforma, cor, turmas) => {
    try {
        if (!uid) {
            notifyError('Não conseguimos identificar você');
            return false;
        }

        await adicionarLimite(uid);

        const plataformaRef = await firestore.collection('privado')
            .doc(uid).collection('plataformas').get();
        
        if (plataformaRef.docs.length >= 20) {
            notifyError('Você já possui 20 ou mais plataformas cadastradas');
            return false;
        }

        const plataformaDoc = await firestore.collection('privado')
            .doc(uid).collection('plataformas').doc(plataforma).get();

        if (!plataformaDoc.exists) {
            await firestore.collection('privado')
            .doc(uid).collection('plataformas').doc(plataforma).set({
                plataforma: plataforma,
                status: 'Ativa',
                turmas: turmas.length,
                cor: cor,
                atualizada: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } else {
            notifyError('Essa plataforma já existe');
            return false;
        }

        const criandoTurmasNaPlataforma = await Promise.all(turmas.map(async (val) => {
            await firestore.collection('privado')
            .doc(uid).collection('plataformas').doc(plataforma)
            .collection('turmas').doc(val.turma).set({
                turma: val.turma,
                ensino: val.ensino,
                atualizada: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }));

        if (criandoTurmasNaPlataforma.length === turmas.length) {
            return true;
        }

        return false;
    } catch (error) {
        console.error('Erro ao criar plataforma e turmas:', error);
        return 'erro';
    }
});

const excluirPlataforma = verificarLimite(async (uid, plataforma) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const plataformaRef = await firestore.collection('privado')
        .doc(uid).collection('plataformas').doc(plataforma);
        if ((await plataformaRef.get()).exists) {
            const excluindo = await plataformaRef.delete();
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

const excluirTurmaDaPlataforma = verificarLimite(async (uid, plataforma, turma) => {
    if (!uid) {
        notifyError('Não conseguimos identificar você');
        return false;
    }
    try {
        await adicionarLimite(uid);
        const plataformaRef = await firestore.collection('privado')
        .doc(uid).collection('plataformas')
        .doc(plataforma);
        const turmaRef = await firestore.collection('privado')
        .doc(uid).collection('plataformas')
        .doc(plataforma).collection('turmas')
        .doc(turma);
        if ((await turmaRef.get()).exists) {
            const excluindo = await turmaRef.delete();
            const turmasAtualizadas = parseInt(((await plataformaRef.get()).data().turmas) - 1);
            if (turmasAtualizadas === 0) {
                const excluindoPlataforma = await excluirPlataforma(uid, plataforma);
            } else {
                const atualizando = await plataformaRef.update({
                    turmas: turmasAtualizadas,
                    atualizada: firebase.firestore.FieldValue.serverTimestamp()
                });
            }
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return 'erro';
    }
});

export { coletarLimite, coletarPlataformas, coletarTurmasDasPlataformas, criarPlataforma, excluirPlataforma, excluirTurmaDaPlataforma }