import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './Bar.css';

const GraficoBar = ({ turma, dados }) => {
  const chartRef = useRef(null);
  const myChartRef = useRef(null);
  const [plataforms, setPlataforms] = useState(['1ª Série A', '1ª Série B']);
  const [colors, setColors] = useState(['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFFFFF']);
  const [datas, setDatas] = useState([]);
  const [getDataLoaded, setGetDataLoaded] = useState(true);

  useEffect(() => {
    const atualizarDadosDoGrafico = (serie) => {
      const serieData = dados.filter(item => item.turma === serie);
      if (serieData.length > 0) {
        setPlataforms(Object.keys(serieData[0]).filter(key => key !== 'id' && key !== 'turma' && key !== 'ensino' && key !== 'atualizada'));
        setDatas(Object.values(serieData[0]).filter(value => typeof value === 'number'));
      }
    };

    atualizarDadosDoGrafico(turma);
  }, [turma, dados]);

  useEffect(() => {
    if (!dados || !turma || !plataforms || !datas) return;

    const ctx = chartRef.current.getContext('2d');
    if (myChartRef.current) {
      myChartRef.current.destroy();
    }
    myChartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: plataforms,
        datasets: [{
          label: 'Porcentagem',
          data: datas,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }]
      },
      options: {
        plugins: {
          legend: {
            display: true,
            onClick: null,
            labels: {
              generateLabels: (chart) => {
                const { data } = chart;
                return data.labels.map((label, index) => ({
                  text: label,
                  fillStyle: data.datasets[0].backgroundColor[index] || '#EEEEEE',
                  strokeStyle: data.datasets[0].borderColor[index] || '#EEEEEE',
                  lineWidth: data.datasets[0].borderWidth || '#EEEEEE',
                  fontColor: '#FFFFFF',
                }));
              }
            }
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
            grid: {
              color: '#888',
            },
            ticks: {
              color: '#FFFFFF',
            }
          },
          x: {
            grid: {
              color: '#888',
            },
            ticks: {
              color: '#FFFFFF',
            }
          }
        },
      }
    });

    return () => {
      if (myChartRef.current) {
        myChartRef.current.destroy();
      }
    };
  }, [getDataLoaded, turma, plataforms, datas, colors]);

  return (
    <div className='grafico-bar'>
      <canvas ref={chartRef} />
    </div>
  );
};

export default GraficoBar;