import './css/CriarPlataforma.css';

// Logos
import Alura from '../../img/plataformas/alura.png';
import KhanAcademy from '../../img/plataformas/khanAcademy.png';
import LeiaSP from '../../img/plataformas/leiaSP.png';

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { coletarPlataformas, coletarTurmasDasPlataformas, criarPlataforma, excluirTurmaDaPlataforma } from '../../firebase/plataforma';
import { coletarTurmas } from '../../firebase/turma';
import localStorageManager from '../../localStorageManager';
import Popup from '../Popup';

// Toastify
import { notifySuccess, notifyError, NotificationContainer } from '../../toastifyServer';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/custom-toast.css';

// ICONS
import { MdCreateNewFolder } from "react-icons/md";
import { HiMiniChartBar } from "react-icons/hi2";
import { IoPersonCircle, IoSearch } from "react-icons/io5";
import { TbSettingsFilled } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { FaPix, FaTrashCan } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { BsClipboard2Data } from "react-icons/bs";
import { enviarPorcentagemDaTurma } from '../../firebase/graficos';

const AdicionarDadosPlataformasApp = ({ plataformasList, turmasList, nomePlataforma, statusPopupAdicionar, fecharPopup, turmasDaPlataformaList }) => {

    // LocalStorage
    const limiteLocal = localStorageManager.getItem('limite') || 0;
    const nomeLocal = localStorageManager.getItem('nome') || null;
    const uidLocal = localStorageManager.getItem('uid') || null;
    const emailLocal = localStorageManager.getItem('email') || null;

    const data = new Date();
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const diaFormatado = dia < 10 ? `0${dia}` : dia;
    const mesFormatado = mes < 10 ? `0${mes}` : mes;
    const dataFormatada = `${diaFormatado}/${mesFormatado}/${ano}`;

    const navigate = useNavigate();

  function getTopPositionRelativeToPage(element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = window.scrollY || window.pageYOffset;
    return rect.top + scrollTop;
  }

  const animacoes = () => {
    const elements = document.querySelectorAll('[data-animation]');
    const classAnimation = "animationClass";
    const windowTop = window.scrollY + ((window.innerHeight * 4.5) / 4);
    
    elements.forEach( async (element) => {
      const positionElemento = await getTopPositionRelativeToPage(element);
      if (Number(windowTop) >= positionElemento - 100) {
        element.classList.add(classAnimation);
      }
    });
  }

  useEffect(() => {
    animacoes();
    window.addEventListener('scroll', animacoes);
    return () => {
      window.removeEventListener('scroll', animacoes);
    };
  }, []);

  // Modais
  const [plataformas, setPlataformas] = useState(plataformasList);
  const [turmas, setTurmas] = useState(turmasList);
  const [turmasDaPlataforma, setTurmasDaPlataforma] = useState(turmasDaPlataformaList);
  const [popupAdicionar, setPopupAdicionar] = useState(statusPopupAdicionar);
  const [popupEditar, setPopupEditar] = useState(false);
  const [popupExcluir, setPopupExcluir] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [mdFormularios, setMdFormularios] = useState(true);
  const [formularioSelecionado, setFormularioSelecionado] = useState('');
  
  // Inputs
  const [inputNomePlataforma, setInputNomePlataforma] = useState(nomePlataforma);
  const [inputPesquisarPlataforma, setInputPesquisarPlataforma] = useState('');
  
  // Formulários
  // Formulário: Alura
  const [datasAluraFazer, setDatasAluraFazer] = useState([]);
  const [datasAluraFeitos, setDatasAluraFeitos] = useState([]);
  const addDatasAluraFazer = (value, index) => {
    setDatasAluraFazer(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };
  const addDatasAluraFeitos = (value, index) => {
    setDatasAluraFeitos(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };

  // Formulário: Khan Academy
  const [datasKhanAcademyFazer, setDatasKhanAcademyFazer] = useState([]);
  const [datasKhanAcademyFeitos, setDatasKhanAcademyFeitos] = useState([]);
  const addDatasKhanAcademyFazer = (value, index) => {
    setDatasKhanAcademyFazer(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };
  const addDatasKhanAcademyFeitos = (value, index) => {
    setDatasKhanAcademyFeitos(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };

  // Formulário: Leia SP
  const [datasLeiaSPFazer, setDatasLeiaSPFazer] = useState([]);
  const [datasLeiaSPFeitos, setDatasLeiaSPFeitos] = useState([]);
  const addDatasLeiaSPFazer = (value, index) => {
    setDatasLeiaSPFazer(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };
  const addDatasLeiaSPFeitos = (value, index) => {
    setDatasLeiaSPFeitos(prevData => {
        const newData = [...prevData];
        while (newData.length <= index) {
          newData.push(0);
        }
        newData[index] = Number(value);
        return newData;
    });
  };


  // Mostrar Popups
  const handleMostrarPopupAdicionar = async (plataforma) => {
    if (!turmas || turmas.length === 0) {
        notifyError('Você precisa criar uma turma primeiro!');
    } else {
      setPopupAdicionar(true);
      setInputNomePlataforma(plataforma);
      const turmasDaPlataformaList = await coletarTurmasDasPlataformas(uidLocal, plataforma);
      setTurmasDaPlataforma(turmasDaPlataformaList);
    }
  }

  const handleFechar = () => {
    setPopupAdicionar(false);
    setMdFormularios(true);
    setFormularioSelecionado('');
    setDatasAluraFazer([]);
    setDatasAluraFeitos([]);
    setDatasKhanAcademyFazer([]);
    setDatasKhanAcademyFeitos([]);
  }

  // Salvar dados da plataforma
  const handleAdicionar = async () => {
    var tamanho = turmasDaPlataforma.length;
    try {
      const concluido = await Promise.all( turmasDaPlataforma.map( async (turma, index) => {
        const porcentagemTurma = await formatarPorcentagem(formularioSelecionado, index);
        const enviando = await enviarPorcentagemDaTurma(uidLocal, nomeLocal, inputNomePlataforma, turma.ensino, turma.turma, porcentagemTurma);
        return enviando;
      }));
      if (concluido) {
        notifySuccess(`Dados da plataforma '${inputNomePlataforma}' salvos com sucesso!`);
        handleFechar();
      }
    } catch (error) {
      console.log(error);
      notifyError('Houve algum erro!');
    }
  }

  // Atualizar Dados
  useEffect(() => {
    setPlataformas(plataformasList);
    setTurmas(turmasList);
    setTurmasDaPlataforma(turmasDaPlataformaList);
    setCarregando(false);
    setPopupAdicionar(statusPopupAdicionar);
  }, [plataformasList, turmasList, turmasDaPlataformaList, statusPopupAdicionar]);

  const formatarPorcentagem = async (formulario, index) => {
    var fazer = 0;
    var feito = 0;
    var porcentagem = 0;
    try {
      if (formulario === 'aluraStart') {
        fazer = datasAluraFazer[index];
        feito = datasAluraFeitos[index];
        porcentagem = Number(Math.max(0, Math.min(((feito / fazer) * 100).toFixed(2), 100))) || 0;
        return porcentagem;
      } else if (formulario === 'khanAcademy') {
        fazer = datasKhanAcademyFazer[index];
        feito = datasKhanAcademyFeitos[index];
        porcentagem = Number(Math.max(0, Math.min(((feito / fazer) * 100).toFixed(2), 100))) || 0;
        return porcentagem;
      } else if (formulario === 'leiaSP') {
        fazer = datasLeiaSPFazer[index];
        feito = datasLeiaSPFeitos[index];
        porcentagem = Number(Math.max(0, Math.min(((feito / fazer) * 100).toFixed(2), 100))) || 0;
        return porcentagem;
      }
    } catch (error) {
      console.log(error);
      notifyError('Houve algum erro!');
      return false;
    }
  }
  
  return (
    <>
        {/* Popups */}
        {popupAdicionar && (
            <Popup classNome={'full'} handleFechar={() => {
                handleFechar();
                fecharPopup();
            }} handleSalvar={handleAdicionar}>
                <div className='input'>
                    <label>Nome da Plataforma</label>
                    <input disabled readOnly defaultValue={inputNomePlataforma} className='bloqueado' type='text' />
                </div>
                {mdFormularios ? (
                    <div className='input'>
                        <label>Formulários</label>
                        <button onClick={() => {
                            setMdFormularios(false);
                            setFormularioSelecionado('aluraStart');
                        }} className='btn-formularios'>
                            <img className='logo mr-5' src={Alura} />
                            <p>Alura Start</p>
                        </button>
                        <button onClick={() => {
                            setMdFormularios(false);
                            setFormularioSelecionado('khanAcademy');
                        }} className='btn-formularios'>
                            <img className='logo' src={KhanAcademy} />
                            <p>Khan Academy</p>
                        </button>
                        <button onClick={() => {
                            setMdFormularios(false);
                            setFormularioSelecionado('leiaSP');
                        }} className='btn-formularios'>
                            <img className='logo' src={LeiaSP} />
                            <p>Leia SP</p>
                        </button>
                    </div>
                ) : (
                    <>
                        {formularioSelecionado === 'aluraStart' && (
                            <>
                                <h1 className='fs-17 ml-10 mt-10 mb-10'>Formulário: <strong>Alura Start</strong></h1>
                                {turmasDaPlataforma.map((turma, index) => (
                                    <div key={index} className='input'>
                                        <label>
                                            Turma: <strong>{turma.turma}</strong> 
                                            <strong className='porcentagem'>
                                                {datasAluraFazer[index] && datasAluraFeitos[index] && datasAluraFazer[index] !== 0 ? 
                                                    `Porcentagem: ${Math.max(0, Math.min(((datasAluraFeitos[index] / datasAluraFazer[index]) * 100).toFixed(2), 100))}%` : 
                                                    <></>
                                                }
                                            </strong>
                                        </label>
                                        <input className='mb-15' placeholder='Total de projetos a fazer. ex: 80' onChange={(e) => addDatasAluraFazer(e.target.value, index)} type='number' />
                                        <input placeholder='Total de projetos já feitos. ex: 40' onChange={(e) => addDatasAluraFeitos(e.target.value, index)} type='number' />
                                    </div>
                                ))}
                            </>
                        )}
                        {formularioSelecionado === 'khanAcademy' && (
                            <>
                                <h1 className='fs-17 ml-10 mt-10 mb-10'>Formulário: <strong>Khan Academy</strong></h1>
                                {turmasDaPlataforma.map((turma, index) => (
                                    <div key={index} className='input'>
                                        <label>
                                            Turma: <strong>{turma.turma}</strong> 
                                            <strong className='porcentagem'>
                                                {datasKhanAcademyFazer[index] && datasKhanAcademyFeitos[index] && datasKhanAcademyFazer[index] !== 0 ? 
                                                    `Porcentagem: ${Math.max(0, Math.min(((datasKhanAcademyFeitos[index] / datasKhanAcademyFazer[index]) * 100).toFixed(2), 100))}%` : 
                                                    <></>
                                                }
                                            </strong>
                                        </label>
                                        <input placeholder='Índice a fazer. ex: 2' onChange={(e) => addDatasKhanAcademyFazer(e.target.value, index)} className='mb-15' type='number' />
                                        <input placeholder='Índice feito. ex: 1' onChange={(e) => addDatasKhanAcademyFeitos(e.target.value, index)} type='number' />
                                    </div>
                                ))}
                            </>
                        )}
                        {formularioSelecionado === 'leiaSP' && (
                            <>
                                <h1 className='fs-17 ml-10 mt-10 mb-10'>Formulário: <strong>Leia SP</strong></h1>
                                {turmasDaPlataforma.map((turma, index) => (
                                    <div key={index} className='input'>
                                        <label>
                                            Turma: <strong>{turma.turma}</strong> 
                                            <strong className='porcentagem'>
                                                {datasLeiaSPFazer[index] && datasLeiaSPFeitos[index] && datasLeiaSPFazer[index] !== 0 ? 
                                                    `Porcentagem: ${Math.max(0, Math.min(((datasLeiaSPFeitos[index] / datasLeiaSPFazer[index]) * 100).toFixed(2), 100))}%` : 
                                                    <></>
                                                }
                                            </strong>
                                        </label>
                                        <input placeholder='Livros a serem lidos. ex: 4' onChange={(e) => addDatasLeiaSPFazer(e.target.value, index)} className='mb-15' type='number' />
                                        <input placeholder='Livros lidos. ex: 2' onChange={(e) => addDatasLeiaSPFeitos(e.target.value, index)} type='number' />
                                    </div>
                                ))}
                            </>
                        )} 
                    </>
                )}
                
            </Popup>
        )}
    </>
  );
}

export default AdicionarDadosPlataformasApp;