// subscriptionManager.js

const listeners = [];
let isNotifying = false; // Variável para controlar o estado de notificação

const subscriptionManager = {
  subscribe(listener) {
    listeners.push(listener);

    return () => {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    };
  },

  notify() {
    if (isNotifying) {
      return;
    }

    isNotifying = true;

    // Notifica os ouvintes de forma assíncrona para evitar problemas de sincronização
    Promise.resolve().then(() => {
      listeners.forEach(listener => listener());
      isNotifying = false; // Marca a notificação como concluída
    });
  }
};

export default subscriptionManager;